const ApiNames = {
  authenticateWeb: "authenticateWeb",
  signup: "user/signupWeb",
  verifyOtp: "user/verifyOtpWeb",
  loginVerifyOtp: "user/verifyLoginOTPWeb",
  checkOperator: "user/checkOperator/",
  subscribe: "user/subscribe",
  gameDetails: "games/gameDetail/",
  mediaPopularstreams: "media/popularstreams/",
  video: "media/livestreams",
  gamesList: "games/list",
  videoDetail: "media/view/",
  streamerPopular: "streamer/popular/100/",
  gamesDownloads: "games/game_downloads",
  GetFlow: "user/getFlow",
  awarenessFlow: "user/awarenessFlow",
  subscribeWeb: "user/subscribeWeb",
  checkUser: "user/checkUser/",
  subscribeFastFlow: "user/ffSubscribe",
  loginWeb: "user/loginWeb",
  utm: "user/utmList",
  streamerSubscribe: "streamer/subscribe",
  streamerUnsubscribe: "streamer/unsubscribe",
  userDetails: "user/details",
  saveProfile: "user/saveProfile",
  uploadPicture: "user/uploadPicture",
  unsubscribeWeb: "user/unsubscribeWeb",
  gamesFollow: "games/follow",
  gamesUnfollow: "games/unfollow",
  mainVideo: "media/mainVideo",
  searchTop: "search/top",
  streamerVideo: "streamer/details/",
  // userSignup: "user/signup/",
};

export default ApiNames;
