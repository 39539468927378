import React from 'react'
import "../style/awareness.css";
import MainImage from "../Assets/Images/awr-main-img.webp";

const Awareness = () => {
  return (
    <div className="awareness-page">
      <div className="image" style={{ maxWidth: "375px", margin: "auto" }}>
        <img
          src={MainImage}
          width={340}
          height={598}
          alt="awareness main image"
        />
      </div>
    </div>
  );
}

export default Awareness
