import React, { useState, useEffect, useContext } from "react";
import Home from "../Components/Home";
import { fetchGet } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import UserContext from "../Utils/User_Context";

function HomeScreen(props) {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [videoImageUrlnew, setVideoImageUrlnew] = useState("");
  const [videoUrlnew, setVideoUrlnew] = useState("");
  const [videoImageUrl, setVideoImageUrl] = useState("");
  const [videoUrl5, setVideoUrl5] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [streamData, setStreamData] = useState([]);
  const [LiveMainVideoData, setLiveMainVideoData] = useState([]);

  const [gameData, setGameData] = useState([]);
  const [webGameData, setWebGameData] = useState([]);
  const [isLoader, setIsloader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    setIsloader(true);
    if (userContext.token) {
      setTimeout(setIsloader(false), 5000);
      getVideoData(`/${userContext.msisdn}/20`);

      downloadGames();
      instantGames();
      bannerSliderAPI();
      mainVideoHandler();
      handleWindowSize();
    }
  }, [userContext.token]);

  const handleWindowSize = () => {
    if (window.innerWidth <= 767) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  };

  const Temp2 = (index) => {
    navigate("/video?videoId=" + LiveMainVideoData[index].id, {
      state: {
        data: LiveMainVideoData[index],
        allData: videoUrl,
      },
    });
  };
  const Temp = (index) => {
    navigate("/video?videoId=" + videoUrl[index].id, {
      state: {
        data: videoUrl[index],
        allData: videoUrl,
      },
    });
  };

  const liveStreamLink = () => {
    navigate("/video", {
      state: {
        data: "http://islam360.econceptions.mobi:1935/live/echotest1_source/playlist.m3u8",
      },
    });
  };

  // ------------------------------------- Banner Slider API - Popular Videos API -------------------------------------

  const bannerSliderAPI = async (e) => {
    try {
      let response = await fetchGet(
        ApiNames.mediaPopularstreams + `${userContext.msisdn}/5`,
        userContext.token
      );

      if (response.status === "0") {
        setStreamData(response.streams);

        const videoImages = response.streams.map((stream) => stream.videoImage);
        setVideoImageUrlnew(videoImages);
        setVideoUrl5(response.streams);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // --------------------------------------- Main Video API ---------------------------------------//
  const mainVideoHandler = async (e) => {
    try {
      let response = await fetchGet(ApiNames.mainVideo, userContext.token);

      if (response.status === "0") {
        setLiveMainVideoData(response.main_video);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // --------------------------------------- Live Streams API ---------------------------------------

  const getVideoData = async (e) => {
    try {
      let response = await fetchGet(ApiNames.video + e, userContext.token);

      if (response.status === "0") {
        setStreamData(response.streams);

        const videoImages = response.streams.map((stream) => stream.videoImage);
        // live stream video images
        setVideoImageUrl(videoImages);
        // live stream video urls
        setVideoUrl(response.streams);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // --------------------------------------- Game List API ---------------------------------------

  // ---------------------------------------  Instant Games API (WEB) ---------------------------------------

  const instantGames = async (e) => {
    try {
      let response = await fetchGet(
        ApiNames.gamesList + "/20/3",
        userContext.token
      );

      if (response.status === "0") {
        setWebGameData(response.games);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // --------------------------------------- Download  Games API (Mobile)  ---------------------------------------

  const downloadGames = async (e) => {
    try {
      let response = await fetchGet(
        ApiNames.gamesList + "/20/2",
        userContext.token
      );
      if (response.status === "0") {
        setGameData(response.games);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const navigateGameDetail = (data) => {
    navigate("/gameinnerpage?gameId=" + data.id, {
      state: {
        data: data,
      },
    });
  };
  const onHandelSeeAll = (index, val) => {
    if (index === 0) {
      navigate("/streams");
    } else {
      navigate("/allgames", {
        state: {
          val: val,
        },
      });
    }
  };

  const onHandelTagSearch = (tag) => {
    navigate("/search", {
      state: {
        tag: tag,
      },
    });
  };

  return (
    <>
      <Home
        videoImageUrl={videoImageUrl}
        videoImageUrlnew={videoImageUrlnew}
        videoUrlnew={videoUrlnew}
        videoUrl={videoUrl}
        gameData={gameData}
        streamData={streamData}
        webGameData={webGameData}
        isLoader={isLoader}
        onHandelSeeAll={onHandelSeeAll}
        Temp2={Temp2}
        Temp={Temp}
        liveStreamLink={liveStreamLink}
        navigateGameDetail={navigateGameDetail}
        showModal={showModal}
        handleShow={handleShow}
        handleClose={handleClose}
        LiveMainVideoData={LiveMainVideoData}
        onHandelTagSearch={onHandelTagSearch}
        smallScreen={smallScreen}
      />
    </>
  );
}

export default HomeScreen;
