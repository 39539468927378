import React, { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import "../style/subscription.css";
import Logo from "../Assets/Images/landing-logo.webp";
import cardImg from "../Assets/Images/main-img.webp";
import vr from "../Assets/Images/vr.webp";
import gamecontroller from "../Assets/Images/game-controller.webp";
import gamecharacters from "../Assets/Images/game-characters.webp";
import mbmainimg from "../Assets/Images/mb-main-img.webp";

const LandigOtp = (props) => {
  // const handleResendClick = () => {
  //   setSeconds(30);
  //   setShowCounter(true);
  //   setShowResend(false);
  // };

  // ph number input box valications
  function keyFunction(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const validChars = /^[0-9]+$/;

    if (!validChars.test(keyValue)) {
      event.preventDefault();
    }
  }

  return (
    <div className="px-0 subs-screen">
      <div className="animated-bg">
        {/* <div className="lines">
          <span style={{ "--i": 11 }}></span>
          <span style={{ "--i": 12 }}></span>
          <span style={{ "--i": 22 }}></span>
          <span style={{ "--i": 28 }}></span>
          <span style={{ "--i": 23 }}></span>
          <span style={{ "--i": 11 }}></span>
          <span style={{ "--i": 12 }}></span>
          <span style={{ "--i": 22 }}></span>
          <span style={{ "--i": 28 }}></span>
          <span style={{ "--i": 23 }}></span>
          <span style={{ "--i": 18 }}></span>
          <span style={{ "--i": 12 }}></span>
          <span style={{ "--i": 16 }}></span>
          <span style={{ "--i": 20 }}></span>
          <span style={{ "--i": 25 }}></span>
          <span style={{ "--i": 18 }}></span>
          <span style={{ "--i": 21 }}></span>
          <span style={{ "--i": 26 }}></span>
          <span style={{ "--i": 17 }}></span>
          <span style={{ "--i": 13 }}></span>
          <span style={{ "--i": 20 }}></span>
          <span style={{ "--i": 28 }}></span>
          <span style={{ "--i": 23 }}></span>
          <span style={{ "--i": 18 }}></span>
          <span style={{ "--i": 12 }}></span>
        </div> */}

        <div className="otpouterDiv outerDivMobile">
          <div className="container">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="logo paralogo">
                    <img src={Logo} alt="" />
                  </div>

                  <div className="newParamWraper">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="subOuterbox">
                          <div className="dis-mobile">
                            <img src={mbmainimg} alt="" />
                          </div>
                          <div className="subscribeBox">
                            <div className="absoluteImgs">
                              <img
                                src={gamecharacters}
                                className="gamecharacters"
                                alt="img"
                              />
                              <img
                                src={gamecontroller}
                                className="gamecontroller"
                                alt="img"
                              />
                              <img src={vr} className="vr-img" alt="img" />
                            </div>

                            <div
                              className="round-gradient"
                              style={{ height: "96.5%" }}
                            ></div>
                            <div className="subinnerBox newparam">
                              <div className="subscriptionText">
                                {/* <h3>
                            Subscribe &amp; Win <br />
                            Rs 10,000
                          </h3> */}

                                <div className="cardImg">
                                  <img src={cardImg} alt="main img" />
                                </div>
                                <p className="getotp">Enter OTP</p>
                              </div>

                              <div className="otpInput">
                                <OTPInput
                                  className="input-fields"
                                  value={props.OTP}
                                  onChange={(value) =>
                                    props.handleChange(value)
                                  }
                                  // onChange={props.setOTP}
                                  autoFocus
                                  OTPLength={4}
                                  otpType="number"
                                  disabled={false}
                                />
                                {props.errorMsg && (
                                  <p className="subs-error">{props.errorMsg}</p>
                                )}
                              </div>
                              <div>
                                {props.isloader === false ? (
                                  <button
                                    className="otpBtn confirmbtn"
                                    id="button-addon2"
                                    onClick={() => props.onPressNext(null)}
                                  >
                                    SUBSCRIBE
                                  </button>
                                ) : (
                                  <button className="otpBtn" id="button-addon2">
                                    <div className="spinner-container-small">
                                      <div className="loading-spinner-small"></div>
                                    </div>
                                  </button>
                                )}
                                <div className="resend-otp">
                                  {props.showCounter ? (
                                    <p className="">
                                      {" "}
                                      Didn’t Receive OTP?{" "}
                                      {/* <span style={{ color: "#C4C4C4" }}>
                                        Resend
                                      </span> */}
                                      <div
                                        className="m-flex"
                                        style={{ justifyContent: "center" }}
                                      >
                                        {/* {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#fff"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <path d="M12 6L12 12 16 14"></path>
                                  </svg>{" "} */}
                                        <span> 00:{props.seconds}</span>
                                      </div>
                                    </p>
                                  ) : (
                                    <p>
                                      {" "}
                                      Didn’t Receive OTP?{" "}
                                      <span
                                        onClick={() =>
                                          props.onResendOtpPressed()
                                        }
                                      >
                                        Resend
                                      </span>
                                    </p>
                                  )}
                                </div>
                              </div>

                              <p className="taxpermonth">
                                Service Charges: Up to 10 PKRs/Day
                              </p>
                              <div className="links">
                                <a href="/privacy-policy" target="_blank">
                                  Privacy Policy
                                </a>
                                <span></span>
                                <a href="/terms-condition" target="_blank">
                                  Terms &amp; Conditions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandigOtp;
