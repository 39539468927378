import React from "react";
import Navbar from "./Navbar";
import followers from "../Assets/Images/followers.png";
import streamer from "../Assets/Images/streamer.webp";
import centerModeSliderImg3 from "../Assets/Images/centerModeSliderImg3.png";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { ImgUrl } from "../Utils/FetchApi";

const UserProfile = (props) => {
  const location = useLocation();
  return (
    <div>
      <Navbar />
      <div className="mainBgSection top-padding">
        <div className="container">
          <div className="shotHeadingContainer">
            <img
              src={`${ImgUrl}${location.state.profilePicture}`}
              className="userProfileImg"
              alt="Banner Slider"
            />
            <div className="screenShotsWraper">
              {/* <div className="tagsBtns">
                <div className="tagitems">FPS</div>
                <div className="tagitems">FPS</div>
                <div className="tagitems">FPS</div>
              </div> */}
              <div
                className="browseMainhead"
                data-text={`${location.state?.streamerName} ${location.state.streamerLastName}`}
              >
                {location.state?.streamerName} {location.state.streamerLastName}
              </div>
              {/* <div className="shotDesc" style={{ margin: 0 }}>
                {props.gameDetailList?.description}
              </div> */}
              <div className="browseInfo">
                <img src={followers} alt="" />
                <p>{props.subscriberCount}</p>
                <span>Followers</span>

                {/* <img src={views} alt="" />
                <p>23954</p>
                <span>Views</span> */}
              </div>

              <div>
                <button type="button" className="d-btn mx-0">
                  {props.channelSubscrib ? (
                    <span
                      onClick={() => {
                        props.onhandelUnsub();
                      }}
                    >
                      UnFollow
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        props.onhandelSub();
                      }}
                    >
                      Follow
                    </span>
                  )}
                </button>
              </div>
              <div style={{ color: "red", marginTop: "5px" }}>
                {/* {props.errorMsg} */}
              </div>
            </div>
          </div>
          <div
            className="tabsItems"
            style={{ background: "transparent", backdropFilter: "unset" }}
          >
            <div className="tabsbtns">
              <div className="tabsLeftside">
                <button className="active">Recent Streams</button>
                {/* <button>Recently Started</button> */}
              </div>
            </div>
          </div>
          {/* videos section*/}

          <Container>
            <div className="playgamesWraper flex">
              <p style={{ paddingTop: "30px" }}>Videos</p>
            </div>

            <Row xs={1} sm={2} md={4} className="g-4 mobileCenterLiveStrm">
              {props.streamerDetail?.map((video, index) => (
                <>
                  <Col
                    onClick={() => {
                      props.onhandleVideoStream(index);
                    }}
                  >
                    <Card className="mt-3 allCardBg cardLiveStrm">
                      <Card.Img
                        variant="top"
                        className="cardImgLiveStrm"
                        src={`${ImgUrl}${video.videoImage}`}
                      />
                      <Card.Body className="LiveStrmBodyFont">
                        <Card.Title className="LiveStrmTitFont">
                          {video.title}
                        </Card.Title>

                        <div className="streamersInfo">
                          <div>
                            <img
                              className="streamerImg"
                              src={`${ImgUrl}${video.userImage}`}
                              alt=""
                            />
                          </div>
                          <div className="stDetail">
                            <Card.Text>{video.channelTitle}</Card.Text>
                            <Card.Text>{video.viewsCount} views</Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ))}
            </Row>
          </Container>
          {/* videos section*/}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default UserProfile;
