import React from "react";
import "../../style/PrivacyStyle.css";
const TermsAndConditions = () => {
  return (
    <div className="jazz_discount_outer" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <div className="discount_inner">
          <h2>Terms & Conditions</h2>
          <h3>General</h3>
          <p>Game Club is a gaming platform that offers Mobile, HTML, Multiplayer, and Single Player Games. These games run online within a browser/mobile app and must be downloaded to install on mobile devices.</p><br></br>
          <p>By using or accessing our platform, you accept that you are bound by and agree to follow these terms and conditions:</p><br></br>
          <h3>Acceptance of terms</h3><br></br>
          <p>You accept these Terms and Conditions as well as our Privacy Policy by using our platform. You are not permitted to use the platform if you disagree with any of the terms and conditions. If you download our app from the Google Play Store, you will be subject to the Google Play Terms of Service as well. If there is a conflict between the Google Play Terms of Service and this Agreement regarding your use of the App, this Agreement will take precedence.</p><br></br>
          <h3>User Profile</h3><br></br>
          <p>You might need to register in order to use some of the platform's features. You guarantee that the information you submit during the registration procedure is true, up to date, and full.</p><br></br>

          <h3>Account Security</h3><br></br>
          <p>You are in charge of keeping your account login details private. Any unauthorized use of your account should be reported to us right away.</p><br></br>

            <h3>Online Conduct</h3><br></br>
            <p>As a user, you agree to only use the Services for lawful purposes. The following are examples of prohibited activities:</p><br></br>
            <p>Criminal or tortious activity, such as child pornography, fraud, obscene material trafficking, drug dealing, gambling, harassment, stalking, spamming, spimming, sending viruses or other harmful files, copyright infringement, patent infringement, or theft of trade secrets</p><br></br>
            <p>Promoting illegal or tortious activities or conduct that is abusive, threatening, obscene, defamatory, or libelous</p><br></br>
            <p>Using sexually explicit or pornographic photos and/or pictures</p><br></br>
            <p>Attempting to circumvent, disable, or otherwise interfere with security-related features of the Services or features that prevent or restrict the use or copying of any content or impose limitations on the use of the Services or the content contained therein</p><br></br>

            <p>The use of cheats, exploits, automation software, bots, hacks, mods, or any other unauthorized software designed to modify or interfere with the Services, as well as the use of cheats or exploits</p><br></br>

          
            <p>Interfering with, disrupting, or imposing an undue burden on the Services or the networks or services to which the Services are linked</p><br></br>

            <p>Using multiple accounts, manual procedures, bots, scripts, or other processes to accumulate or "farm" Virtual Items</p><br></br>
            <p>Commercially using the Services, including transferring Virtual Items in exchange for "real-world" money.</p><br></br>

            <h3>Eligibility </h3><br></br>
            <p>To use the GC Platform, you must:</p><br></br>
            <ul>
              <li>Be at least 13 years old, or the legal age in your jurisdiction, whichever is higher.</li>
              <li>If you are between 13 and 17 years old, have the permission of your parent or legal guardian to use the platform.</li>
              <li>Agree to be bound by the GCTerms of Service.</li>
              <li>Be a resident of the country in which the billing provider you used to register is located.</li>
            
            </ul><br>

            </br>

            <p>If you do not meet all of these criteria, you may not use the GC Platform.</p><br></br>

            <h3>Purchases / Payments / Refunds</h3><br></br>
            <p>Platform Fee: The service is provided to Billing Provider’s customers at a subscription fee. Ssubscribers can opt for the following subscription plans to avail access to GC.</p><br></br>

            <table>
            <tr>
              <th>Pricing Model</th>
              <th>Price Exclusive Tax</th>
              <th>Price with tax</th>
              <th>Validity(days)</th>
            </tr>
            <tr>
              <td>Daily*</td>
              <td>8.37</td>
              <td>10</td>
              <td>1</td>
            </tr>
           
          </table><br></br>

          <h3>*Daily Price point variation:</h3>
          <p>Under this pricing model, we will make two pricing attempts daily, and the corresponding amounts will be deducted for a continuous 90-day period. These attempts are as follows:</p><br></br>
          <p>1.	Rs. 10 per day, including taxes </p><br></br>
          <p>2.	Rs. 7 per day, including taxes </p><br></br>

          <ul>

            <li>If the subscriber will not be charged at PKR 10/Day, Then he/she will be attempted for charging at PKR 7/ Day on daily basis.</li>
            <li>•	In case of failure on any price points in 90 days, we will purge the customer from the platform.</li>

          </ul><br></br>

          <h3>Platform do not Guarantees</h3><br></br>
          <p>To meet the expectations of the User</p><br></br>
          <p>To be available without interruption or in a timely, reliable, or fault-free manner</p><br></br>
          <p>Results to be in accordance with the users' expectations and deemed correct and reliable</p><br></br>
          <p>Quality of products, services, information, or other material won or obtained by the User will meet users expectations.</p><br></br>
          <p>Any advice or information, written or oral, obtained from GC or through the Platform shall constitute any warranty not expressly stated in these Terms.</p><br></br>

          <h3> Information to Service users</h3><br></br>

          <p>By activating the Service, the subscriber agrees to receive text messages about new features/updates in GC. Text messages are sent from the Billing Provider's Network and are free of charge. GC reserves the right to change the subscription fee or to run special offers from time to time in which it can provide a discounted or free subscription to Billing Provider's customers.</p><br></br>

          <h3>Limitation of Liability</h3><br></br>
          <p> Users expressly acknowledge and agree that GC bears no responsibility for any direct or indirect damages, including, but not limited to, delays, loss of profits, goodwill, data licenses, or financial losses resulting from:</p><br></br>
          <p> The organizer is not responsible for any loss, damage, or injury resulting from participation in the lucky draw or the use of prizes.</p><br></br>
          <p> Participants agree to release the organizer from any liability associated with the lucky draw and cannot claim anything through a legal case for damages</p><br></br>
          <p>Use of the Platform or inability to use it.</p><br></br>
          <p>Costs incurred from acquiring or replacing products or services based on items, data, information, or services obtained through the Platform.</p><br></br>
          <p>Messages received or transactions conducted via the Platform.</p><br></br>
          <p>Third-party claims against the subscriber.</p><br></br>
          <p>Unauthorized access to or alterations of data transmissions.</p><br></br>
          <p>Any other circumstances related to the Platform.</p><br></br>
          <p>GC disclaims liability for damages related to Platform access, functionality, quality, errors                  (including spelling errors and bugs), and other factors.</p><br></br>
          <p>GC is not liable for indirect or unforeseeable damages under any circumstances.</p><br></br>
          <p>Users are responsible for maintaining the confidentiality of their credentials and must promptly notify GC of any actual or suspected unauthorized account use. Users may be held liable for GC's losses or losses to other parties due to unauthorized account use.</p><br></br>


          <h3>Cases of unforeseen circumstances</h3><br></br>
          <p>GC is not liable for any damages arising from force majeure or similar circumstances that directly or indirectly impact GC, unreasonably complicating its activities. Examples of such events include labor disputes, government actions, war or threats of war, sabotage, civil unrest, demonstrations, natural disasters, and other events beyond GC's control. In the event of force majeure hindering, delaying, or complicating Platform maintenance, GC has the right to suspend or limit Platform services until further notice.</p><br></br>
          <h3>Barring</h3><br></br>
          <ul>
            <li>Unresponsive Winners: Users who fail to respond to the call centre within 24 hours of being contacted will forfeit their prize.</li>
            <li>Violation of Rules: The organizer reserves the right to disqualify participants who breach the terms and conditions or engage in fraudulent behaviour.</li>
            <li>Campaign Changes: The organizer holds the right to alter, suspend, or terminate the lucky draw at any time without prior notice.</li>
          </ul><br></br>

          <h3>Promotion</h3><br></br>
          <p>GC may display third-party products on the Platform through digital advertisements, whether obtained directly from advertisers or sourced from ad networks. GC is not responsible for the privacy practices or content shared by business partners, advertisers, sponsors, or other websites linked on the Platform. Users are urged to review the privacy policy and terms of use of these third parties before visiting their websites to protect their interests.</p><br></br>


          <h3>Proprietary Rights</h3>
          <p>The content on the Platform is safeguarded by international copyright laws and treaties. Any reproduction or distribution of materials, including text, photos, videos, music, and software programs, is strictly prohibited unless explicitly permitted. GC retains all rights not explicitly granted in this agreement.</p><br></br>
          <p>The trade name, logotype, and associated trademarks, product names, and slogans on the Platform belong to GC and cannot be used without prior written approval. Use of the Platform does not grant users any license or right to utilize these trademarks or names.</p><br></br>

          <h3>Legal Framework and Jurisdiction</h3><br></br>
          <p>These Terms are subject to the laws of the territory. You acknowledge that GC operates exclusively in Islamabad, Pakistan. Therefore, any personal jurisdiction over us is limited to Pakistan. You agree not to raise claims against us in any court outside Pakistan. Any disputes between the Subscriber and GC will be exclusively heard and adjudicated by a court of competent jurisdiction in Pakistan.</p><br></br>

          <h3>Stand-Alone Provisions</h3><br></br>
          <p>If any provision of the Terms is found by a court of competent jurisdiction to be unenforceable, it will be enforced to the maximum extent permissible to reflect the parties' intent. The rest of the Terms will remain in full force.</p><br></br>

          <h3>Renunciation</h3><br></br>
          <p>No provision in this Agreement is considered waived or any breach excused unless it's in writing and signed by GC. GC's consent or waiver of one breach doesn't imply consent to, waiver of, or excuse for any other or subsequent breach by the User.</p><br></br>

          <h3>Terms of Termination</h3><br></br>
          <p>This Agreement remains valid until further notice.</p><br></br>

          <h3>Termination </h3><br></br>
          <p>GC reserves the right to, at its sole discretion and without prior notice, suspend, freeze, or terminate a User's access to the Platform for security violations, including unauthorized data access, probing system vulnerabilities, or using the Platform without authorization, in violation of these Terms or applicable law. Such security breaches may lead to civil or criminal liability, and GC will cooperate with law enforcement in such cases.</p><br></br>
          <p>GC may, at its sole discretion and without prior notice, suspend, freeze, or terminate a Subscriber Account under various circumstances, including violations of the Agreement or Platform instructions, suspicion of criminal activity, fraud, safety concerns, damage to GC's reputation, or as required by law or a competent authority.</p><br></br>

          <h3>Reimbursement</h3><br></br>

          <p>Users are obligated to indemnify, defend, and hold GC and its affiliates harmless from any losses, claims, suits, damages, and expenses arising from:</p><br></br>

          <p>1. User's breach of these Terms.</p><br></br>
          <p>2. Third-party claims related to User's use of the GC Platform, Tournament participation, or rewards claimed.</p><br></br>
          <p>3. User's violation of another party's rights, including intellectual property.</p><br></br>
          <p>4. User's violation of applicable laws.</p><br></br>

          <p>Despite any contrary provision in these Terms, GC and its affiliates cannot be held liable to the User or anyone claiming through the User for any subject covered by these Terms.</p><br></br>

          <h3>Annotations</h3><br></br>

          <ul>
            <li><b>“Subscription”</b> is an access model of the product where subscribers get access to the Portal</li>
            <li><b>“Subscriber”</b> is a User (defined below) who has paid a subscription fee as defined to access that content.</li>
            <li><b>“Games”</b> shall mean the HTML5 and mobile games that made available to Users on the Platform</li>
            <li><b>“Personal Information”</b> means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person.</li>
            <li><b>“Platform” </b>means the website available at <a href="https://gameclub.digitology.live/">https://gameclub.digitology.live</a> and any sub domains ( “Website”) and the GC mobile application on Android ( “Application”).</li>
            <li><b>“Reward” </b>is an award given to a Subscriber for being a top scorer in a particular Segment.</li>
            <li><b>“Territory” </b>shall mean Pakistan.</li>
            <li><b>“Billing Provider” </b>shall mean Jazz.</li>
          </ul>

        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
