import React, { useContext, useEffect, useState } from "react";
import Landing from "../Components/Landing";
import { useLocation, useNavigate } from "react-router-dom";
import ApiNames from "../Constants/ApiNames";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import UserContext from "../Utils/User_Context";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Landingtwo from "../Components/Landingtwo";
import { encode as base64_encode } from "base-64";

const LandingScreen = () => {
  const userContext = useContext(UserContext);

  const search = useLocation().search;
  const utm_source = new URLSearchParams(search).get("utm_source");
  const _url = new URLSearchParams(search).get("msisdn");
  const encodeMsisdn = encodeURIComponent(_url);
  var searchParams = new URLSearchParams(search);
  var parameter = searchParams.get("parameter")
    ? searchParams.get("parameter")?.toLocaleLowerCase()
    : searchParams.get("Parameter")
    ? searchParams.get("Parameter")?.toLocaleLowerCase()
    : "";

  const [phoneNo, setPhoneNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isloader, setIsLoader] = useState(false);
  const [userPackage, setUserPackage] = useState("");
  const [heOtpFlag, setHeOtpFlag] = useState(false);
  const [smartWatchImg, setSmartWatchImg] = useState(false);
  const queryParams = new URLSearchParams(search);
  const perameterP = getParameterCaseInsensitive("p");
  const [isModel, setIsModel] = useState(true);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (window.location.protocol === "https:") {
  //     let url = window.location.href;
  //     url = url.replace("https", "http");
  //     window.location.replace(url);
  //   }

  // }, []);

  useEffect(() => {
    if (userContext.token) {
      authFlow();
    }
  }, [userContext.token]);

  //   Auth Flow Api
  const authFlow = async () => {
    try {
      let response = await fetchGet(
        ApiNames.GetFlow +
          "?utm_source=" +
          utm_source +
          "&msisdn=" +
          encodeMsisdn,
        userContext.token
      );
      if (response.status === 0) {
        headerEnrichment(response.result);
      }
    } catch (err) {}
  };

  const headerEnrichment = (response) => {
    try {
      if (response.isSpecialFlow === false) {
        if (response.msisdn) {
          setPhoneNo(response.msisdn);
          setUserPackage(response.subPackage);
          if (perameterP === "1") {
            setTimeout(() => {
              setIsModel(false);
            }, 2000);
          }
          if (response.signIn) {
            let url = window.location.origin;
            let encodedMsisdn = base64_encode(response.msisdn);
            url = `${url}/?user=${encodedMsisdn}`;
            if (url.includes("http:")) {
              url = url.replace("http", "https");
            }
            window.location.replace(url);
          } else {
            setHeOtpFlag(true);
          }
          tagManagerEvents("heUser", utm_source);
        } else {
          tagManagerEvents("wifiUser", utm_source);
          setPhoneNo("");
        }
      } else if (response.isSpecialFlow === true) {
        if (response.msisdn && response.otp) {
          if (parameter?.toUpperCase() === "10K") {
            navigate("/pin", {
              state: {
                parameter: "10K",
                phoneNumber: response.msisdn,
                OTP: response.otp,
                utm_source: utm_source,
                milliseconds: response.milliseconds,
                OtpFill: false,
                HeFlag: null,
                isFastFlow: response.fastFlow,
                isSpecialFlow: response.isSpecialFlow,
                userPackage: response.subPackage,
                exists: false,
                otpId: response.otpId,
              },
            });
          } else {
            navigate("/pin", {
              state: {
                parameter: parameter,
                phoneNumber: response.msisdn,
                OTP: response.otp,
                HeFlag: null,
                utm_source: utm_source,
                milliseconds: response.milliseconds,
                OtpFill: false,
                isFastFlow: response.fastFlow,
                isSpecialFlow: response.isSpecialFlow,
                userPackage: response.subPackage,
                exists: false,
                otpId: response.otpId,
              },
            });
          }
        }
      }
    } catch (error) {}
  };

  const onTextChange = (e) => {
    let value = e.target.value;
    if (value === "0" || !value.startsWith(3)) {
      setPhoneNo("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setPhoneNo(value);
      }
    }
  };

  const onNextPressed = () => {
    if (phoneNo.toString().length === 10) {
      setErrorMsg("");
      setIsLoader(true);
      userSignup();
    } else {
      setErrorMsg("Please enter a valid mobile number. e.g 3xxxxxxxxx");
    }
  };

  const userSignup = async () => {
    let data = {
      msisdn: phoneNo,
      sourceInfo: "",
      os: "",
      gameId: "",
    };
    try {
      let response = await fetchPost(ApiNames.signup, userContext.token, data);

      if (response.status === 1) {
        setIsLoader(false);
        setErrorMsg(
          "Service is only for Jazz customers. Kindly enter a working Jazz number."
        );
      } else {
        if (response.status === 0 && response.result === "Success") {
          setIsLoader(false);
          if (parameter?.toUpperCase() === "10K") {
            navigate("/pin", {
              state: {
                parameter: "10K",
                phoneNumber: phoneNo,
                utm_source: utm_source,
                OTP: null,
                HeFlag: heOtpFlag ? response.otp : null,
                OtpFill: false,
                exists: response.exists,
                userPackage: userPackage,
                otpId: response.otpId,
              },
            });
          } else {
            navigate("/pin", {
              state: {
                parameter: parameter,
                phoneNumber: phoneNo,
                utm_source: utm_source,
                OTP: null,
                HeFlag: heOtpFlag ? response.otp : null,
                OtpFill: false,
                exists: response.exists,
                userPackage: userPackage,
                otpId: response.otpId,
              },
            });
          }
        } else {
          setIsLoader(false);
          setErrorMsg("Unable to send OTP, Please try again.");
        }
      }
    } catch (err) {
      setIsLoader(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  function getParameterCaseInsensitive(name) {
    for (const [key, value] of queryParams.entries()) {
      if (key.toLowerCase() === name.toLowerCase()) {
        return value;
      }
    }
    return null; // Return null if parameter is not found
  }

  const onHandleJoinNow = () => {
    setIsModel(false);
  };

  // const checkOperator = async () => {
  //   try {
  //     let response = await fetchGet(
  //       ApiNames.checkOperator + phoneNo,
  //       userContext.token
  //     );
  //     if (
  //       response.status === 0 &&
  //       response.network.toLowerCase() === "others"
  //     ) {
  //       setIsLoader(false);
  //       setErrorMsg(
  //         "Service is only for Jazz customers. Kindly enter a working Jazz number."
  //       );
  //     } else {
  //       sendOTP();
  //     }
  //   } catch (err) {
  //     setIsLoader(false);
  //     setErrorMsg("Something went wrong, Please try again.");
  //   }
  // };

  // const sendOTP = async (userPackage) => {
  //   let data = {
  //     msisdn: phoneNo,
  //     sourceInfo: "",
  //   };
  //   try {
  //     let response = await fetchPost(ApiNames.signup, userContext.token, data);
  //     if (response.status === 0 && response.result === "Success") {
  //       console.log(response);
  //       setIsLoader(false);
  //       if (parameter?.toUpperCase() === "10K") {
  //         navigate("/pin", {
  //           state: {
  //             parameter: "10K",
  //             phoneNumber: phoneNo,
  //             utm_source: utm_source,
  //             OTP: null,
  //             HeFlag: heOtpFlag ? response.otp : null,
  //             OtpFill: false,
  //             exists: response.exists,
  //             userPackage: userPackage,
  //           },
  //         });
  //       } else {
  //         navigate("/pin", {
  //           state: {
  //             parameter: parameter,
  //             phoneNumber: phoneNo,
  //             utm_source: utm_source,
  //             OTP: null,
  //             HeFlag: heOtpFlag ? response.otp : null,
  //             OtpFill: false,
  //             exists: response.exists,
  //             userPackage: userPackage,
  //           },
  //         });
  //       }
  //     } else {
  //       setIsLoader(false);
  //       setErrorMsg("Unable to send OTP, Please try again.");
  //     }
  //   } catch (err) {
  //     setIsLoader(false);
  //     setErrorMsg("Something went wrong, Please try again.");
  //   }
  // };

  return (
    <>
      {parameter?.toUpperCase() === "10K" ? (
        <Landingtwo
          isloader={isloader}
          onTextChange={onTextChange}
          onNextPressed={onNextPressed}
          phoneNo={phoneNo}
          errorMsg={errorMsg}
        />
      ) : (
        <Landing
          isloader={isloader}
          onTextChange={onTextChange}
          onNextPressed={onNextPressed}
          phoneNo={phoneNo}
          errorMsg={errorMsg}
          smartWatchImg={smartWatchImg}
          parameter={parameter}
          // isModel={isModel}
          isModel={false}
          onHandleJoinNow={onHandleJoinNow}
        />
      )}
    </>
  );
};

export default LandingScreen;
