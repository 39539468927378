import React, { useState, useEffect, useContext } from "react";
import VideoStream from "../Components/VideoStream";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import UserContext from "../Utils/User_Context";

const VideoStreamScreen = (props) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  let { state } = useLocation();
  const [streamData, setStreamData] = useState([]);
  const [streamAllData, setStreamAllData] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [gameDetail, setGameDetail] = useState(null);
  const [videoUrl, setVideoUrl] = useState(state?.data.url);
  const [type, setType] = useState(state?.type);
  const [searchParams, setSearchParams] = useSearchParams();
  const [videoId, setVideoId] = useState();
  const [viewCount, setViewCount] = useState();

  useEffect(() => {
    if (state?.data) {
      setViewCount(state.data.viewsCount);
      setStreamAllData(state.allData);
      setVideoId(state.data.id);
      console.log(typeof state.data.id);
      if (userContext.token) getVideoDetail(state.data.id);
    } else {
      let videoid = searchParams.get("videoId");
      if (videoid) {
        setVideoId(Math.floor(videoid));
        if (userContext.token) getVideoDetail(videoid);
      } else {
        navigate("/");
      }
      getVideoList(`/${userContext.msisdn}/50`);
    }
  }, [userContext.token]);

  useEffect(() => {
    if (userContext.token && state) {
      getGameDetail(state?.data.gameId);
    }
  }, [userContext.token, state]);

  const getVideoDetail = async (id) => {
    try {
      let response = await fetchGet(
        ApiNames.videoDetail + id,
        userContext.token
      );
      if (response.status === "0") {
        setStreamData(response.video);
        setVideoUrl(response.video.url);
        getGameDetail(response.video.gameId);
      }
    } catch (err) {
      console.log("catch");
    }
  };

  const getGameDetail = async (gameId) => {
    let Api_Name = "";
    if (userContext.msisdn) {
      Api_Name = ApiNames.gameDetails + gameId + "/" + userContext.msisdn;
    } else {
      Api_Name = ApiNames.gameDetails + gameId;
    }
    try {
      let response = await fetchGet(Api_Name, userContext.token);
      if (response.status === "0") {
        setGameDetail(response.gameDetail);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const getVideoList = async (e) => {
    try {
      let response = await fetchGet(ApiNames.video + e, userContext.token);
      if (response.status === "0") {
        setStreamAllData(response.streams);
      }
    } catch (err) {
      console.log("catch");
    }
  };

  const onHandleBrowseVideo = () => {
    navigate("/browseVideo?gameId=" + gameDetail.game.id, {
      state: {
        BrowseGameData: gameDetail,
      },
    });
  };

  const onHandleChangeVideo = (id) => {
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;

    // Update the value of 'videoid'
    queryParams.set("videoId", id); // Set the new value here

    // Update the URL with the new query parameters
    currentUrl.search = queryParams.toString();
    window.history.replaceState(null, "", currentUrl.href);
    const result = streamAllData.filter((data) => {
      return data.id === id;
    });
    console.log(result);
    setVideoId(id);
    getGameDetail(result[0].gameId);
    setStreamData(result[0]);
    setVideoUrl(result[0].url);
    setViewCount(result[0].viewsCount);
  };

  return (
    <>
      <VideoStream
        type={type}
        streamData={streamData}
        streamAllData={streamAllData}
        videoUrl={videoUrl}
        onHandleChangeVideo={onHandleChangeVideo}
        gameDetail={gameDetail}
        onHandleBrowseVideo={onHandleBrowseVideo}
        errMsg={errMsg}
        videoId={videoId}
        viewCount={viewCount}
      />
    </>
  );
};

export default VideoStreamScreen;
