import React, { useEffect } from "react";
import Navbar from "./Navbar";
import "../style/UserSetting.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import streamer from "../Assets/Images/streamer.webp";
import SmallLoader from "./Common/SmallLoader";
import Footer from "./Footer";

const UserSetting = (props) => {
  const validate = Yup.object({
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    nick: Yup.string().required("Please enter display name"),
    bioText: Yup.string().required("Please enter bio"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: props.userDetail.firstName,
      lastName: props.userDetail.lastName,
      nick: props.userDetail.nick,
      bioText: props.userDetail.bioText,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      props.onHandelChange(
        values.firstName,
        values.lastName,
        values.nick,
        values.bioText
      );
    },
  });

  useEffect(() => {
    formik.setFieldValue("firstName", props.userDetail.firstName, false);
    formik.setFieldValue("lastName", props.userDetail.lastName, false);
    formik.setFieldValue("nick", props.userDetail.nick, false);
    formik.setFieldValue("bioText", props.userDetail.bioText, false);
  }, [props.userDetail]);
  return (
    <div>
      <Navbar />
      <div className="user-profile-bg">
        <div className="userSettingSection">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="uploadAvatar">
                  <div className="Ua-wraper">
                    <div className="avatarImg">
                      {props.imgUrl ? (
                        <img src={props.imgUrl} alt="" />
                      ) : (
                        <img src={streamer} alt="" />
                      )}
                    </div>
                    <div className="uploadBtn">
                      {props.imgLoader === false ? (
                        <>
                          <input
                            className="choseFile"
                            type="file"
                            accept=".webp,image/png, image/gif, image/jpeg"
                            id="file-input"
                            name="ImageStyle"
                            onChange={(e) => props.onuploadImagepressed(e)}
                          />
                          <label class="d-btn" for="file-input">
                            Change picture
                          </label>
                        </>
                      ) : (
                        <SmallLoader />
                      )}
                      <span></span>
                      <p className="disclaimerText">
                        Must be JPEG, PNG, or WEBP and cannot exceed 3MB.
                      </p>
                      <p className="disclaimerText">{props.errorImgMsg}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="uploadAvatar">
                  <p className="b-info">Basic Info</p>
                  <div className="formWraper">
                    <form action="" onSubmit={formik.handleSubmit}>
                      <div className="form__Group">
                        <div className="ft-input">
                          <div className="input__Group">
                            <label for="first-name">First Name</label>
                            <input
                              type="text"
                              id="first-name"
                              className="user-input"
                              name="firstName"
                              onChange={formik.handleChange}
                              value={formik.values.firstName}
                              required
                              maxLength={15}
                            ></input>
                            {<p className="error">{formik.errors.firstName}</p>}
                          </div>
                          <div className="input__Group">
                            <label for="first-name">Last Name</label>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                              type="text"
                              id="first-name"
                              className="user-input"
                              name="lastName"
                              required
                              maxLength={15}
                            ></input>
                            {<p className="error">{formik.errors.lastName}</p>}
                          </div>
                        </div>
                        <div className="input__Group fullwidth">
                          <label for="first-name">Display Name</label>
                          <input
                            type="text"
                            id="first-name"
                            className="user-input"
                            name="nick"
                            required
                            onChange={formik.handleChange}
                            value={formik.values.nick}
                            maxLength={15}
                          ></input>
                          {<p className="error">{formik.errors.nick}</p>}
                        </div>
                        <div className="input__Group fullwidth">
                          <label for="first-name">Bio</label>
                          <textarea
                            name="bioText"
                            id=""
                            rows="5"
                            onChange={formik.handleChange}
                            value={formik.values.bioText}
                            maxLength={300}
                          ></textarea>
                          {<p className="error">{formik.errors.bioText}</p>}
                        </div>

                        <div className="saveChanges">
                          {props.isLoader === false ? (
                            <button className="d-btn">
                              Save Changes
                            </button>
                          ) : (
                            <div
                              className="d-btn"
                              style={{ paddingTop: "7px" }}
                            >
                              <SmallLoader />
                            </div>
                          )}
                        </div>
                      </div>
                      <p
                        className="error-Msg"
                        
                      >
                        {props.errorMsg}
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className="streamingKey">
                  <p className="Uatext">Profile Setting</p>
                  <div className="input__Group streamkey">
                    <div className="sk">
                      <input
                        type="text"
                        id="first-name"
                        className="user-input"
                        name="first-name"
                        required
                      ></input>
                      <p className="shotnote">
                        You may update your username again in 1 week
                      </p>
                    </div>
                    <div className="generateBtn">
                      <button className="d-btn-play2">Save Changes</button>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default UserSetting;
