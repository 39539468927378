import React, { useContext } from "react";
import { useState, useEffect } from "react";
import Game from "../Components/Game";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../Utils/User_Context";

const GameScreen = (props) => {
  const userContext = useContext(UserContext);
  const { state } = useLocation();
  const [webGameData, setwebGameData] = useState([]);
  const [mobGameData, setMobGameData] = useState([]);
  const [mPlayerGameData, setmPlayerGameData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gameDataList, setGameDataList] = useState([]);
  const [tabIndex, setTabIndex] = useState(state ? state?.val : 1);
  const [filteredData, setFilteredData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    if (userContext.token) {
      webGames();
    }
  }, [userContext.token]);

  // ---------------------------- Web Games API ----------------------------

  const webGames = async () => {
    try {
      let response = await fetchGet(
        ApiNames.gamesList + "/100/3",
        userContext.token
      );

      if (response.status === "0") {
        let res = response.games;
        if (res.length === 0) {
          setwebGameData(null);
          setGameDataList(null);
        } else {
          setwebGameData(response.games);
          setGameDataList(response.games);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  // ---------------------------- Mobile Games API ----------------------------

  const mobileGames = async () => {
    try {
      setIsLoading(true);
      let response = await fetchGet(
        ApiNames.gamesList + "/100/2",
        userContext.token
      );
      if (response.status === "0") {
        let res = response.games;
        if (res.length === 0) {
          setMobGameData(null);
          setGameDataList(null);
        } else {
          setMobGameData(response.games);
          setGameDataList(response.games);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  // ---------------------------- Multiplayer Games API ----------------------------

  const multiplayerGames = async () => {
    try {
      setIsLoading(true);
      let response = await fetchGet(
        ApiNames.gamesList + "/100/1",
        userContext.token
      );
      if (response.status === "0") {
        let res = response.games;
        if (res.length === 0) {
          setmPlayerGameData(null);
          setGameDataList(null);
        } else {
          setmPlayerGameData(response.games);
          setGameDataList(response.games);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const PlayNow = (webGameUrl) => {
    if (userContext.msisdn) {
      navigate("/playGames", {
        state: {
          webGameUrl: webGameUrl,
        },
      });
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };

  const Download = async (index) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: index,
      };
      try {
        let response = await fetchPost(
          ApiNames.gamesDownloads,
          userContext.token,
          data
        );
        if (response.status === 0) {
          console.log("success");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const navigateGameDetail = (data) => {
    navigate("/gameinnerpage?gameId=" + data.id, {
      state: {
        data: data,
      },
    });
  };

  // Search Handler

  const searchHandler = (query) => {
    setInputValue(query);
    const filtered = gameDataList.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // Search Handler

  const TabSelector = (element) => {
    setFilteredData([]);
    setInputValue("");
    if (element === 1 && webGameData.length === 0) {
      setGameDataList([]);
      webGames();
    } else if (element === 2 && mobGameData.length === 0) {
      setGameDataList([]);
      mobileGames();
    } else if (element === 3 && mPlayerGameData.length === 0) {
      setGameDataList([]);
      multiplayerGames();
    }

    if (element === 1 && webGameData.length !== 0) {
      setGameDataList(webGameData);
    } else if (element === 2 && mobGameData.length !== 0) {
      setGameDataList(mobGameData);
    } else if (element === 3 && mPlayerGameData.length !== 0) {
      setGameDataList(mPlayerGameData);
    }
  };

  const onHandelTagSearch = (tag) => {
    navigate("/search", {
      state: {
        tag: tag,
      },
    });
  };

  return (
    <Game
      isLoading={isLoading}
      webGameData={webGameData}
      mobGameData={mobGameData}
      mPlayerGameData={mPlayerGameData}
      webGames={webGames}
      multiplayerGames={multiplayerGames}
      mobileGames={mobileGames}
      PlayNow={PlayNow}
      Download={Download}
      navigateGameDetail={navigateGameDetail}
      TabSelector={TabSelector}
      gameDataList={gameDataList}
      setTabIndex={setTabIndex}
      tabIndex={tabIndex}
      searchHandler={searchHandler}
      filteredData={filteredData}
      inputValue={inputValue}
      onHandelTagSearch={onHandelTagSearch}
    />
  );
};

export default GameScreen;
