// import './App.css';
import "./style/commonStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import { useState, useEffect } from "react";
import LiveStreamScreen from "./Screens/liveStreamScreen";
import HomeScreen from "./Screens/HomeScreen";
import GameScreen from "./Screens/GameScreen";
import VideoStreamScreen from "./Screens/VideoStreamScreen";
import LandingScreen from "./Screens/landingScreen";
import LandigOtpScreen from "./Screens/LandigOtpScreen";
import Streamers from "./Screens/StreamersScreen";
import GameDetailScreen from "./Screens/GameDetailScreen";
import UserSettingScreen from "./Screens/UserSettingScreen";
import GlobalSearchScreen from "./Screens/GlobalSearchScreen";
import ApiNames from "./Constants/ApiNames";
import { fetchGet, fetchPost, ImgUrl } from "./Utils/FetchApi";
import UserContext from "./Utils/User_Context";
import { decode as base64_decode } from "base-64";
import { encode as base64_encode } from "base-64";
import BrowseVideoScreen from "./Screens/BrowseVideoScreen";
import PlayGame from "./Components/PlayGame";
import Tournaments from "./Components/Tournaments";
import TagManager from "react-gtm-module";
import tagManagerEvents from "./Utils/GoogleTagManager";
import Privacypolicy from "./Components/Common/Privacypolicy";
import LandingtwoOtp from "./Components/LandingtwoOtp";
import TermsAndConditions from "./Components/Common/TermsAndConditions";
import UserProfile from "./Components/UserProfile";
import CommingSoonModal from "./Components/CommingSoonModal";
import ChatLive from "./Components/ChatLive";
import Landingtwo from "./Components/Landingtwo";
import LeaderBoard from "./Components/LeaderBoard";
import UserProfileScreen from "./Screens/UserProfileScreen";
import NewLandingPageScreen from "./Screens/SingleLandingScreen";
import LandingMainScreen from "./Screens/LandingMainScreen";
import axios from "axios";
import NewHome from "./Components/NewHome";
import AwarenessScreen from "./Screens/AwarenessScreen";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";
  const [token, setToken] = useState("");
  const [msisdn, setMsisdn] = useState(null);
  const [userDetail, setUserDetail] = useState({});
  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    // let data = {
    //   userName: base64_encode("digitology_1997"),
    //   password: base64_encode("JFIIJHF5YD"),
    // };

    // const apiUrl =
    //   "http://www.dot-jo.biz/appgw/GetPartnerHEMSISDN?partnerId=digitology-851x";
    // axios
    //   .post(apiUrl, data)
    //   .then((response) => {
    //     console.log("Response", JSON.stringify(response.data));
    //   })
    //   .catch((error) => {
    //     console.error(" axios Error:", error);
    //   });

    const tagManagerArgs = {
      gtmId: "GTM-5Z9HCBJD",
    };
    TagManager.initialize(tagManagerArgs);
    setMsisdn(localStorage.getItem("msisdn"));
    GenerateToken();
    requestPremission();
  }, []);

  useEffect(() => {
    if (token) {
      checkUser();
    }

    if (token && msisdn) {
      getUserDetails(msisdn);
    }
  }, [token, msisdn]);

  const requestPremission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        //generate fcm token
        const token = await getToken(messaging, {
          vapidKey:
            "BGd0CIPUcs6dwX8EeWfGkxO35bgMGatPXosBXuv97M2YsGLWLJMw7UcRqt8QQpX8YjwmwvuU9KBT4rmmFQ3iEzc",
        });
        // console.log("im in requestPremission",token)
      } else if (permission === "denied") {
        console.log("you denied permission");
      }
    } catch (error) {
      console.log("notification request denied");
    }
  };
  const getUserDetails = async (number) => {
    try {
      let data = {
        msisdn: number,
      };
      let response = await fetchPost(ApiNames.userDetails, token, data);
      if (response.status === 0) {
        if (response.picture) {
          response.picture = ImgUrl + response.picture;
        }
        setUserDetail(response);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const GenerateToken = async (e) => {
    try {
      let data = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      let response = await fetchPost(ApiNames.authenticateWeb, null, data);
      if (response.status === 0) {
        setToken(response.token);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const checkUser = () => {
    if (localStorage.getItem("msisdn") === null) {
      let url = window.location.href;
      if (
        window.location.pathname === "/" &&
        url.includes("?") &&
        url.includes("user=")
      ) {
        let msisdn = new URLSearchParams(search).get("user");
        msisdn = base64_decode(msisdn);
        if (msisdn.length === 10) {
          localStorage.setItem("msisdn", msisdn);
          setMsisdn(msisdn);
        }
      }
    } else {
      // fetchCheckUser();
    }
  };

  const fetchCheckUser = async () => {
    try {
      let response = await fetchGet(ApiNames.checkUser + msisdn, token);
      if (response.status === 1) {
        localStorage.removeItem("msisdn");
        setMsisdn(null);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const unSubscribe = async () => {
    try {
      let data = {
        msisdn: msisdn,
      };
      let response = await fetchPost(ApiNames.unsubscribeWeb, token, data);
      if (response.status === 0) {
        // Tags
        tagManagerEvents("Unsubscribe", utm_source);
        localStorage.removeItem("user");
        localStorage.removeItem("msisdn");
        updateUserMsisdn("");
        navigate("/");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const updateUserMsisdn = (val) => {
    setMsisdn(val);
  };
  const updateUserDetails = (val) => {
    setUserDetail(val);
  };

  return (
    <>
      <UserContext.Provider
        value={{
          //states
          token,
          msisdn,
          userDetail,
          //functions
          updateUserMsisdn,
          unSubscribe,
          updateUserDetails,
        }}
      >
        <Routes>
          <Route path="/*" element={<Navigate to={"/"} />} />
          <Route path="/" element={<HomeScreen />} />
          {/* <Route path="/new-home" element={<HomeScreen />} /> */}
          <Route path="/playGames" element={<PlayGame />} />
          <Route path="/tournaments" element={<Tournaments />} />
          <Route path="/userprofile" element={<UserProfileScreen />} />
          <Route path="/myprofilesetting" element={<UserSettingScreen />} />
          <Route path="/search" element={<GlobalSearchScreen />}></Route>
          <Route path="/commingsoon" element={<CommingSoonModal />}></Route>
          <Route path="/livechat" element={<ChatLive />}></Route>
          <Route path="/leaderboard" element={<LeaderBoard />}></Route>
          <Route path="/streams" element={<LiveStreamScreen />}></Route>
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/terms-condition" element={<TermsAndConditions />} />
          <Route path="/browseVideo" element={<BrowseVideoScreen />}></Route>
          <Route path="/video" element={<VideoStreamScreen />}></Route>
          <Route path="/streamers" element={<Streamers />}></Route>
          <Route path="/allgames" element={<GameScreen />}></Route>
          <Route path="/gameinnerpage" element={<GameDetailScreen />}></Route>
          <Route
            path="/game/downloadMobileGame"
            element={<GameDetailScreen />}
          ></Route>
          {localStorage.getItem("msisdn") ? (
            <Route element={<Navigate to={"/"} />}></Route>
          ) : (
            <Route path="/phone" element={<LandingMainScreen />}></Route>
          )}
          {localStorage.getItem("msisdn") ? (
            <Route element={<Navigate to={"/"} />}></Route>
          ) : (
            <Route
              exact
              path="/landing"
              element={<LandingMainScreen />}
            ></Route>
          )}
          {localStorage.getItem("msisdn") ? (
            <Route element={<Navigate to={"/"} />}></Route>
          ) : (
            <Route
              exact
              path="/awareness"
              element={<AwarenessScreen />}
            ></Route>
          )}
          <Route path="/pin" element={<LandigOtpScreen />}></Route>
        </Routes>
      </UserContext.Provider>
    </>
  );
}

export default App;
