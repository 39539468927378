import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import followers from "../Assets/Images/followers.png";
import views from "../Assets/Images/views.png";
import "../style/BrowseVideo.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import SmallLoader from "./Common/SmallLoader";
import { ImgUrl } from "../Utils/FetchApi";

const BrowseVideo = (props) => {
  return (
    <div className="px-0">
      <Navbar />
      <div className="outerDiv browserVideo top-padding">
        <div className="container">
          <div className="shotHeadingContainer">
            <img
              src={`${ImgUrl}${props.gameDetailList?.image}`}
              className="shotHeadImg"
              alt="Banner Slider"
            />
            <div className="screenShotsWraper">
              <div className="tagsBtns">
                {props.tagsList.map((tag) => {
                  return <div className="tagitems ">{tag}</div>;
                })}
              </div>
              <div
                className="browseMainhead"
                data-text={props.gameDetailList?.title}
              >
                {props.gameDetailList?.title}
              </div>
              <div className="shotDesc" style={{margin: 0}}>
                {props.gameDetailList?.description}
              </div>
              <div className="browseInfo">
                <img src={followers} alt="" />
                <p>{props.followCount}</p>
                <span>Followers</span>

                <img src={views} alt="" />
                <p>{props.ViewCount}</p>
                <span>Views</span>
              </div>

              <div className="">
                {props.isLoader ? (
                  <div className="d-btn-play2" style={{ paddingTop: "7px" ,marginTop: "35px" }}>
                    <SmallLoader/>
                  </div>
                ) : (
                  <>
                    {props.subscribed ? (
                      <button
                        type="button"
                        className="d-btn-play2 d-btn-playMobile py-1 mx-0"
                        // style={{ marginTop: "35px" }}
                        onClick={() =>
                          props.onHandelUnFollow(props.gameDetailList.id)
                        }
                      >
                        UnFollow
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="d-btn-play2 d-btn-playMobile py-1 mx-0"
                        // style={{ marginTop: "35px" }}
                        onClick={() =>
                          props.onHandelFollow(props.gameDetailList.id)
                        }
                      >
                        Follow
                      </button>
                    )}
                  </>
                )}
              </div>
              <div style={{ color: "red", marginTop: "5px" }}>
                {props.errorMsg}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mainBgSection">
        {/* ----------------------------- Slider ---------------------------- */}
        <Container className="main-padding">
          <h3 className="videoText">Videos</h3>
          <Row xs={1} sm={2} md={4} className="g-4 mobileCenterLiveStrm">
            {props.gameVideoList.map((stream, index) => (
              <Col key={index}>
                <Card
                  className="browseVideoCard"
                  onClick={() => props.onhandleVideoStream(index)}
                >
                  <Card.Img
                    variant="top"
                    className="browseCardImg"
                    src={`${ImgUrl}${stream.videoImage}`}
                  />
                  <Card.Body className="LiveStrmBodyFont">
                    <Card.Title className="b-vid-text">
                      {stream.title}
                    </Card.Title>
                    <div className="streamersInfo">
                      <div>
                        <img
                          className="streamerImg"
                          src={`${ImgUrl}${stream.userImage}`}
                          alt=""
                        />
                      </div>
                      <div className="stDetail">
                        <Card.Text>{stream.channelTitle}</Card.Text>
                        <Card.Text>{stream.viewsCount} views</Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      <Footer />
      </div>
    </div>
  );
};

export default BrowseVideo;
