import React from "react";
// import logo_web_landing from "../Assets/Images/logo_web_landing.webp";
// import newlanding from "../Assets/Images/new-landing.webp";
import "../style/Newlanding.css";

const Landingtwo = (props) => {
  return (
    <div>
      <div class="landing-header">
        <div class="container">
          <a href="java" class="landing-header-logo">
            {/* <img src={logo_web_landing} alt="" /> */}
          </a>
        </div>
      </div>
      <div className="landingWraper">
        <div className="landing-box">
          <div className="inner-box">
            {/* <img src={newlanding} alt="" /> */}
          </div>
          <div className="form-wraper">
            <p className="e-m-n">Enter your Mobile Number to Receive OTP</p>
            <div className="input__group">
              <div className="inputgroupprepend">
                <span className="inputgrouptext">+92</span>
              </div>
              <input
                type="tel"
                autoComplete="off"
                placeholder="XXX - XXX - XXXX"
                value={props.phoneNo}
                onChange={props.onTextChange}
                maxLength="10"
                pattern="3[0-9]{9}"
              />
            </div>
            {props.errorMsg && <p className="subs-error">{props.errorMsg}</p>}
            <div className="form-btn">
              {props.isloader === false ? (
                <button onClick={() => props.onNextPressed()}>Get OTP</button>
              ) : (
                <button>
                  <div className="spinner-container-small">
                    <div className="loading-spinner-small"></div>
                  </div>
                </button>
              )}
            </div>
            <div className="short-text">
              <p>Service Charges: Up to 10 PKRs/Day</p>
            </div>
            <div class="links o-land">
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              <span></span>
              <a href="/terms-condition" target="_blank">
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landingtwo;
