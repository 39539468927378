import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiNames from "../Constants/ApiNames";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import UserContext from "../Utils/User_Context";
import tagManagerEvents from "../Utils/GoogleTagManager";
import { encode as base64_encode } from "base-64";

import NewLandingPage from "../Components/SingleLandingPage";

let adNetwork = "";

function SingleLandingScreen() {
  const userContext = useContext(UserContext);
  const search = useLocation().search;
  const utm_source = new URLSearchParams(search).get("utm_source");
  var searchParams = new URLSearchParams(search);
  var parameter = searchParams.get("parameter")
    ? searchParams.get("parameter")?.toLocaleLowerCase()
    : searchParams.get("Parameter")
    ? searchParams.get("Parameter")?.toLocaleLowerCase()
    : "";

  const [phoneNo, setPhoneNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isloader, setIsLoader] = useState(false);
  const [userPackage, setUserPackage] = useState("");
  const [heOtpFlag, setHeOtpFlag] = useState(false);
  const [OTP, setOTP] = useState("");
  const [userExists, setUserExists] = useState();
  const [openOtpFlag, setOpenOtpFlag] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [showCounter, setShowCounter] = useState(false);

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (window.location.protocol === "https:") {
  //     let url = window.location.href;
  //     url = url.replace("https", "http");
  //     window.location.replace(url);
  //   }
  // }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setShowCounter(false);
    }
  }, [seconds]);

  // let responseChecking = {
  //   fastFlow: false,
  //   isSpecialFlow: false,
  //   milliseconds: 0,
  //   msisdn: "3045602206",
  //   network: null,
  //   otp: "",
  //   signIn: false,
  //   subPackage: "PRE",
  // };

  useEffect(() => {
    if (userContext.token) {
      authFlow();
    }
  }, [userContext.token]);
  useEffect(() => {
    if (userContext.token) {
      checkUtm();
    }
  }, [userContext.token]);

  useEffect(() => {
    if (phoneNo.toString().length === 10) {
      onNextPressed();
    }
  }, [phoneNo]);

  //   Auth Flow Api

  const authFlow = async () => {
    try {
      let response = await fetchGet(
        ApiNames.GetFlow + "?utm_source=" + utm_source,
        userContext.token
      );
      if (response.status === 0) {
        headerEnrichment(response.result);
      }
    } catch (err) {}
  };

  const headerEnrichment = (response) => {
    try {
      if (response.isSpecialFlow === false) {
        if (response.msisdn) {
          setPhoneNo(response.msisdn);
          setUserPackage(response.subPackage);
          setSeconds(30);
          setShowCounter(true);
          setOpenOtpFlag(true);

          if (response.signIn) {
            let url = window.location.origin;
            let encodedMsisdn = base64_encode(response.msisdn);
            url = `${url}/?user=${encodedMsisdn}`;
            if (url.includes("http:")) {
              url = url.replace("http", "https");
            }
            window.location.replace(url);
          } else {
            setHeOtpFlag(true);
          }
          tagManagerEvents("heUser", utm_source);
        } else {
          tagManagerEvents("wifiUser", utm_source);
          setPhoneNo("");
        }
      }
    } catch (error) {}
  };
  const checkUtm = async () => {
    try {
      let response = await fetchGet(ApiNames.utm, userContext.token);
      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (utm_source === e.sf_utm) {
              // setSubUtm("econceptions");
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  // subscribe flow
  const onTextChange = (e) => {
    let value = e.target.value;
    if (value === "0" || !value.startsWith(3)) {
      setPhoneNo("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setPhoneNo(value);
      }
    }
  };

  const checkOperator = async () => {
    try {
      let response = await fetchGet(
        ApiNames.checkOperator + phoneNo,
        userContext.token
      );
      if (
        response.status === 0 &&
        response.network.toLowerCase() === "others"
      ) {
        setErrorMsg(
          "Service is only for Jazz customers. Kindly enter a working Jazz number."
        );
      } else {
        sendOTP();
        setSeconds(30);
        setShowCounter(true);
        setOpenOtpFlag(true);
      }
    } catch (err) {
      setErrorMsg("Something went wrong, Please try again.");
    }
  };
  const sendOTP = async (userPackage) => {
    let data = {
      msisdn: phoneNo,
      sourceInfo: "",
    };
    try {
      let response = await fetchPost(ApiNames.signup, userContext.token, data);
      if (response.status === 0 && response.result === "Success") {
        setUserExists(response.exists);
        if (heOtpFlag) {
          setOTP(response.otp);
        }
      } else {
        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (err) {
      setErrorMsg("Something went wrong, Please try again.");
    }
  };
  // otp flow
  const handleChange = (otp) => {
    setOTP(otp);
  };
  const onPressNext = () => {
    setIsLoader(true);

    if (OTP.length === 4) {
      setErrorMsg("");
      setOTP(OTP);
      verifyOTP();
    } else {
      setOTP("");
      setErrorMsg("Please enter a valid 4 digits OTP.");
      setIsLoader(false);
    }
  };
  const onNextPressed = () => {
    if (phoneNo.toString().length === 10) {
      setErrorMsg("");

      if (userPackage) {
        sendOTP(userPackage);
      } else {
        checkOperator();
      }
    } else {
      setErrorMsg("Please enter a valid mobile number. e.g 3xxxxxxxxx");
    }
  };
  const verifyOTP = async () => {
    if (userExists) {
      try {
        let response = await fetchGet(
          ApiNames.loginVerifyOtp + "?msisdn=" + phoneNo + "&otp=" + OTP,
          userContext.token
        );
        if (response.status === 0) {
          tagManagerEvents("verifiedOTP", utm_source);
          if (userExists) {
            login();
          }
        } else {
          setErrorMsg("Unable to subscribe user, Please try again.");
          setIsLoader(false);
        }
      } catch (err) {
        console.warn(err);
      }
    } else {
      subscriberUser();
    }
  };
  const subscriberUser = async () => {
    let data = {
      msisdn: phoneNo,
      nick: "GW-guestUser",
      otp: OTP,
      utm_source: utm_source,
      subPackage: userPackage,
    };

    try {
      let response = await fetchPost(
        ApiNames.subscribeWeb,
        userContext.token,
        data
      );

      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("signup", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("signup", "econceptions");
        } else {
          tagManagerEvents("signup", utm_source);
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(phoneNo);
        url = `${url}/?user=${encodedMsisdn}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
        setIsLoader(false);
      } else {
        tagManagerEvents("signupFailed", utm_source);
        setErrorMsg("Unable to subscribe user, Please try again.");
        setIsLoader(false);
      }
    } catch (err) {
      tagManagerEvents("signupFailed", utm_source);
      setErrorMsg("Something went wrong, Please try again.");
      setIsLoader(false);
    }
  };

  const login = async () => {
    let data = {
      msisdn: phoneNo,
      otp: OTP,
    };
    try {
      let response = await fetchPost(
        ApiNames.loginWeb,
        userContext.token,
        data
      );

      if (response.status === 0) {
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(phoneNo);
        url = `${url}/?user=${encodedMsisdn}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
        setIsLoader(false);
      } else {
        setErrorMsg("Unable to subscribe user, Please try again.");
        setIsLoader(false);
      }
    } catch (err) {
      setErrorMsg("Something went wrong, Please try again.");
      setIsLoader(false);
    }
  };
  const onResendOtpPressed = () => {
    tagManagerEvents("resendOTP", utm_source);
    setSeconds(30);
    setShowCounter(true);
    setOTP("");
    sendOTP();
  };

  return (
    <>
      <NewLandingPage
        isloader={isloader}
        onTextChange={onTextChange}
        phoneNo={phoneNo}
        errorMsg={errorMsg}
        OTP={OTP}
        setOTP={setOTP}
        handleChange={handleChange}
        onPressNext={onPressNext}
        openOtpFlag={openOtpFlag}
        onResendOtpPressed={onResendOtpPressed}
        seconds={seconds}
        showCounter={showCounter}
      />
    </>
  );
}

export default SingleLandingScreen;
