import React, { useContext, useEffect, useState } from "react";
import GameDetail from "../Components/GameDetail";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import UserContext from "../Utils/User_Context";

const GameDetailScreen = () => {
  const userContext = useContext(UserContext);
  const [gameDetail, setGameDetail] = useState(null);
  const [msisdn, setMsisdn] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.data) {
      setGameDetail(state.data);
      setMsisdn(userContext.msisdn);
    } else {
      let gameId = searchParams.get("gameId");
      if (gameId) {
        let _msisdn = searchParams.get("msisdn");
        if (userContext.msisdn) {
          setMsisdn(userContext.msisdn);
        } else if (_msisdn) {
          setMsisdn(_msisdn);
        }
        if (userContext.token) getGameDetail(gameId);
      } else {
        navigate("/");
      }
    }
  }, [userContext.token]);

  const getGameDetail = async (gameId) => {
    try {
      let response = await fetchGet(
        ApiNames.gameDetails + gameId,
        userContext.token
      );
      if (response.status === "0") {
        setGameDetail(response.gameDetail.game);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const Download = async (index) => {
    try {
      if (msisdn) {
        let data = {
          msisdn: msisdn,
          resourceId: index,
        };

        let response = await fetchPost(
          ApiNames.gamesDownloads,
          userContext.token,
          data
        );
        if (response.status === 0) {
          console.log("success");
        }
      } else {
        window.location.replace(window.location.origin + "/onboarding.html");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const PlayNow = (webGameUrl) => {
    console.warn(webGameUrl);
    if (userContext.msisdn) {
      navigate("/playGames", {
        state: {
          webGameUrl: webGameUrl,
        },
      });
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };
  return (
    <GameDetail
      gameDetail={gameDetail}
      Download={Download}
      PlayNow={PlayNow}
      msisdn={msisdn}
    />
  );
};

export default GameDetailScreen;
