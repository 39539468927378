import React, { useContext, useState } from "react";
import { Container, Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import "../style/LiveStream.css";
import streamer from "../Assets/Images/streamer.webp";
import Navbar from "./Navbar";
import LoaderSpiner from "./LoaderSpiner";
import Footer from "./Footer";
import StreamersScreen from "../Screens/StreamersScreen";
import Streamers from "./Common/Streamers";
import { ImgUrl } from "../Utils/FetchApi";
import { useNavigate } from "react-router-dom";
import UserContext from "../Utils/User_Context";

let taglist = [];
let tagArr = [];
const LiveStream = (props) => {
  console.log(props.tabIndex, "data===>", props.streamingGamesData);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const handleSearchClick = () => {
    if (window.innerWidth <= 767) {
      const input = document.getElementById("searchInput");
      const searchButton = document.getElementById("searchButton");
      const cancelButton = document.getElementById("cancelButton");

      input.style.display = "block";
      searchButton.style.display = "none";
      cancelButton.style.display = "inline-block";
    }
  };

  const handleCancelClick = () => {
    if (window.innerWidth <= 767) {
      const input = document.getElementById("searchInput");
      const searchButton = document.getElementById("searchButton");
      const cancelButton = document.getElementById("cancelButton");

      input.value = ""; // Clear the input value
      input.style.display = "none";
      searchButton.style.display = "inline-block";
      cancelButton.style.display = "none";
    }
  };
  return (
    <>
      <div className="mainBgSection">
        <div className="">
          <div className="container">
            <Navbar />
          </div>
          {/* slider end */}

          <div className="tabsItems liveStream">
            <div className="container">
              <div className="tabsbtns">
                <div className="tabsLeftside">
                  <button
                    className={props.tabIndex === 1 ? "active" : ""}
                    onClick={() => {
                      props.TabSelector(1);
                    }}
                  >
                    Live Streams
                  </button>
                  <button
                    className={props.tabIndex === 2 ? "active" : ""}
                    onClick={() => {
                      props.TabSelector(2);
                    }}
                  >
                    Streaming Games
                  </button>
                  <button
                    className={props.tabIndex === 3 ? "active" : ""}
                    onClick={() => {
                      props.TabSelector(3);
                    }}
                  >
                    Streamers
                  </button>
                </div>

                <div className="tabsSearchBtn">
                  <div className="searchIcon">
                    <input
                      type="text"
                      placeholder="Search"
                      id="searchInput"
                      value={props.inputValue}
                      onChange={(e) => props.searchHandler(e.target.value)}
                      maxLength={10}
                    />
                    <button onClick={handleSearchClick} id="searchButton">
                      <svg
                        stroke="white"
                        strokeWidth="0.3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search header__button-icon iconMobile"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                      </svg>
                    </button>
                    <button
                      style={{ display: "none" }}
                      id="cancelButton"
                      onClick={handleCancelClick}
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tab Data List */}

          {/* {props.isLoader ? (
            <LoaderSpiner />
          ) : ( */}
          <>
            {props.tabIndex === 1 ? (
              <>
                {props.isLoader && props.streamData?.length === 0 ? (
                  <LoaderSpiner />
                ) : (
                  <>
                    {props.filteredData.length === 0 &&
                    props.inputValue !== "" ? (
                      <div className="noDataStyle">No Record Found</div>
                    ) : (
                      <>
                        {props.filteredData.length === 0 &&
                        props.inputValue === "" ? (
                          <Container>
                            <Row
                              xs={1}
                              sm={2}
                              md={4}
                              className="g-4 mobileCenterLiveStrm"
                            >
                              {props.streamData === null ? (
                                <div className="noDataStyle">
                                  No Record Foundsdcd
                                </div>
                              ) : (
                                <>
                                  {props.streamData.map((stream, index) => (
                                    <Col key={index}>
                                      <Card
                                        className="mt-3 allCardBg cardLiveStrm"
                                        onClick={() =>
                                          props.onHandleNavigate(index)
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          className="cardImgLiveStrm"
                                          src={`${ImgUrl}${stream.videoImage}`}
                                        />
                                        <Card.Body className="LiveStrmBodyFont">
                                          <Card.Title className="LiveStrmTitFont">
                                            {stream.title}
                                          </Card.Title>

                                          <div className="streamersInfo">
                                            <div>
                                              <img
                                                className="streamerImg"
                                                src={`${ImgUrl}${stream.userImage}`}
                                                alt=""
                                              />
                                            </div>
                                            <div className="stDetail">
                                              <Card.Text>
                                                {stream.channelTitle}
                                              </Card.Text>
                                              <Card.Text>
                                                {stream.viewsCount} views
                                              </Card.Text>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  ))}
                                </>
                              )}
                            </Row>
                          </Container>
                        ) : (
                          <Container>
                            <Row
                              xs={1}
                              sm={2}
                              md={4}
                              className="g-4 mobileCenterLiveStrm"
                            >
                              {props.filteredData.map((stream, index) => (
                                <Col key={index}>
                                  <Card
                                    className="mt-3 allCardBg cardLiveStrm"
                                    onClick={() =>
                                      props.onHandleNavigate(index)
                                    }
                                  >
                                    <Card.Img
                                      variant="top"
                                      className="cardImgLiveStrm"
                                      src={`${ImgUrl}/${stream.videoImage}`}
                                    />
                                    <Card.Body className="LiveStrmBodyFont">
                                      <Card.Title className="LiveStrmTitFont">
                                        {stream.title}
                                      </Card.Title>

                                      <div className="streamersInfo">
                                        <div>
                                          <img
                                            className="streamerImg"
                                            src={`${ImgUrl}/${stream.userImage}`}
                                            alt=""
                                          />
                                        </div>
                                        <div className="stDetail">
                                          <Card.Text>
                                            {stream.channelTitle}
                                          </Card.Text>
                                          <Card.Text>
                                            {stream.viewsCount} views
                                          </Card.Text>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          </Container>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {props.tabIndex === 2 ? (
                  <>
                    {props.isLoader && props.streamingGamesData.length === 0 ? (
                      <LoaderSpiner />
                    ) : (
                      <>
                        {props.gameFilteredData.length === 0 &&
                        props.inputValue !== "" ? (
                          <div className="noDataStyle">No Record Found</div>
                        ) : (
                          <>
                            {props.gameFilteredData.length === 0 &&
                            props.inputValue === "" ? (
                              <div className="ls-gc">
                                <Container>
                                  <Row
                                    xs={1}
                                    sm={2}
                                    md={2}
                                    lg={4}
                                    className="g-4 "
                                  >
                                    {props.streamingGamesData ? (
                                      <>
                                        {props.streamingGamesData.map(
                                          (data, index) => {
                                            taglist = data.tag;
                                            tagArr = taglist?.split(",");
                                            return (
                                              <Col key={index}>
                                                <Card className=" border-0 rounded-4  gamingCard">
                                                  <div
                                                    style={{
                                                      overflow: "hidden",
                                                    }}
                                                  >
                                                    <Card.Img
                                                      onClick={() =>
                                                        props.navigateGameDetail(
                                                          data
                                                        )
                                                      }
                                                      variant="top"
                                                      src={`${ImgUrl}${data.image}`}
                                                      className="gameCardImg"
                                                    />
                                                  </div>

                                                  <Card.Body className="cardBodyMob">
                                                    <div className="flex">
                                                      <Card.Title className="gc-title">
                                                        {data.title}
                                                      </Card.Title>
                                                      {/* <button type="button" className="d-btn  btnMobile  cardGamBtnMrktPlace ">BUY NOW</button> */}
                                                    </div>
                                                    {/* <Card.Text>{data.description}</Card.Text>
                                                      <Card.Text>{data.viewsCount}</Card.Text> */}
                                                    <div className="shootActionBtn">
                                                      {tagArr?.map((tag) => {
                                                        return (
                                                          <button
                                                            onClick={() =>
                                                              props.onHandelTagSearch(
                                                                tag
                                                              )
                                                            }
                                                            className="cardBtnShGame py-1 d-inline"
                                                          >
                                                            {tag}
                                                          </button>
                                                        );
                                                      })}
                                                    </div>
                                                    {/* <button className='cardBtnLvlUp py-1 d-none d-sm-block d-inline'>Level UP</button> */}
                                                  </Card.Body>
                                                </Card>
                                              </Col>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <div className="noDataStyle">
                                        No Record Found
                                      </div>
                                    )}
                                  </Row>
                                </Container>
                              </div>
                            ) : (
                              <div className="ls-gc">
                                <Container>
                                  <Row
                                    xs={1}
                                    sm={2}
                                    md={2}
                                    lg={4}
                                    className="g-4"
                                  >
                                    {props.gameFilteredData.map(
                                      (data, index) => {
                                        taglist = data.tag;
                                        tagArr = taglist?.split(",");
                                        return (
                                          <Col key={index}>
                                            <Card className=" border-0 rounded-4  gamingCard">
                                              <div
                                                style={{ overflow: "hidden" }}
                                              >
                                                <Card.Img
                                                  onClick={() =>
                                                    props.navigateGameDetail(
                                                      data
                                                    )
                                                  }
                                                  variant="top"
                                                  src={`${ImgUrl}${data.image}`}
                                                  className="gameCardImg"
                                                />
                                              </div>

                                              <Card.Body className="cardBodyMob">
                                                <div className="flex">
                                                  <Card.Title className="gc-title">
                                                    {data.title}
                                                  </Card.Title>
                                                  {/* <button type="button" className="d-btn  btnMobile  cardGamBtnMrktPlace ">BUY NOW</button> */}
                                                </div>
                                                {/* <Card.Text>{data.description}</Card.Text>
                                                      <Card.Text>{data.viewsCount}</Card.Text> */}
                                                <div className="shootActionBtn">
                                                  {tagArr?.map((tag) => {
                                                    return (
                                                      <button
                                                        onClick={() =>
                                                          props.onHandelTagSearch(
                                                            tag
                                                          )
                                                        }
                                                        className="cardBtnShGame py-1 d-inline"
                                                      >
                                                        {tag}
                                                      </button>
                                                    );
                                                  })}
                                                </div>
                                                {/* <button className='cardBtnLvlUp py-1 d-none d-sm-block d-inline'>Level UP</button> */}
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </Container>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {props.tabIndex === 3 ? (
                      <>
                        {props.isLoader && props.streamersData?.length === 0 ? (
                          <LoaderSpiner />
                        ) : (
                          <div className="py-4">
                            {props.streamersData ? (
                              <Streamers
                                inputValue={props.inputValue}
                                streamersData={props.streamersData}
                                SteamersFilteredData={
                                  props.SteamersFilteredData
                                }
                                isLoader={props.isLoader}
                                onhandelUnsub={props.onhandelUnsub}
                                onhandelSub={props.onhandelSub}
                                errMsg={props.errMsg}
                                subIndex={props.subIndex}
                              />
                            ) : (
                              <div className="noDataStyle">No Record Found</div>
                            )}
                          </div>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </>
          {/* )} */}
          {/* Tab Data List */}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LiveStream;
