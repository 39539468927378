import React, { useContext, useEffect, useState } from "react";
import LandigOtp from "../Components/LandigOtp";
import { useLocation, useNavigate } from "react-router-dom";
import ApiNames from "../Constants/ApiNames";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import { encode as base64_encode } from "base-64";
import UserContext from "../Utils/User_Context";
import tagManagerEvents from "../Utils/GoogleTagManager";
import LandingtwoOtp from "../Components/LandingtwoOtp";

let adNetwork = "";

let otpId = "";
const LandigOtpScreen = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userContext = useContext(UserContext);
  const [OTP, setOTP] = useState("");
  const [isloader, setIsLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [showCounter, setShowCounter] = useState(true);
  const [smartWatchImg, setSmartWatchImg] = useState(false);
  // const [otpId, setOtpId] = useState();

  useEffect(() => {
    // if (window.location.protocol === "https:") {
    //   let url = window.location.href;
    //   url = url.replace("https", "http");
    //   window.location.replace(url);
    // }

    if (state === null) {
      navigate("/onboarding.html");
    } else {
      otpId = state.otpId;
      // if (state.parameter === "smartwatch") {
      //   setSmartWatchImg(true);
      // }
      if (state.HeFlag) {
        setOTP(state.HeFlag);
      }
      if (state.OTP !== null) {
        if (state.milliseconds && state.milliseconds >= 0) {
          setTimeout(() => onPressNext(state.OTP), state.milliseconds);
        } else {
          onPressNext(state.OTP);
        }
      }
      // setOtpId(state.otpId);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setShowCounter(false);
    }
  }, [seconds]);

  useEffect(() => {
    if (userContext.token) {
      checkUtm();
    }
  }, [userContext.token]);

  const checkUtm = async () => {
    try {
      let response = await fetchGet(ApiNames.utm, userContext.token);
      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (state.utm_source === e.sf_utm) {
              // setSubUtm("econceptions");
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const onPressNext = (otp) => {
    setIsLoader(true);
    if (otp) {
      setOTP(otp);
      setErrorMsg("");
      subscriberUser(
        state.isSpecialFlow && state.isFastFlow
          ? ApiNames.subscribeFastFlow
          : ApiNames.subscribeWeb,
        otp
      );
    } else {
      if (OTP.length === 4) {
        setErrorMsg("");
        setOTP(OTP);
        verifyOTP(OTP);
      } else {
        setOTP("");
        setErrorMsg("Please enter a valid 4 digits OTP.");
        setIsLoader(false);
      }
    }
  };

  const handleChange = (otp) => {
    setOTP(otp);
  };

  // -------------------------------------------------- Verify API  --------------------------------------------------

  const verifyOTP = async (otp) => {
    if (state.exists) {
      // try {
      //   let response = await fetchGet(
      //     ApiNames.loginVerifyOtp +
      //       "?msisdn=" +
      //       state.phoneNumber +
      //       "&otp=" +
      //       otp,
      //     userContext.token
      //   );
      //   if (response.status === 0) {
      //     tagManagerEvents("verifiedOTP", state.utm_source);
      //     if (state.exists) {
      //       login();
      //     }
      //   } else {
      //     setErrorMsg("Unable to subscribe user, Please try again.");
      //     setIsLoader(false);
      //   }
      // } catch (err) {
      //   console.warn(err);
      // }
      subscriberUser(ApiNames.subscribeWeb, otp);
    } else {
      subscriberUser(
        state.isSpecialFlow && state.isFastFlow
          ? ApiNames.subscribeFastFlow
          : ApiNames.subscribeWeb,
        otp
      );
    }
    //  else {
    //   let data = {
    //     msisdn: state.phoneNumber,
    //     otp: otp,
    //   };
    //   try {
    //     let response = await fetchPost(
    //       ApiNames.verifyOtp,
    //       userContext.token,
    //       data
    //     );
    //     if (response.status === 0) {
    //       tagManagerEvents("verifiedOTP", state.utm_source);

    //       subscriberUser(
    //         state.isSpecialFlow && state.isFastFlow
    //           ? ApiNames.subscribeFastFlow
    //           : ApiNames.subscribeWeb,
    //         otp
    //       );
    //     } else {
    //       tagManagerEvents("failedOTP", state.utm_source);
    //       setErrorMsg("Entered OTP is not valid, Please try again.");
    //       setIsLoader(false);
    //     }
    //   } catch (err) {
    //     tagManagerEvents("failedOTP", state.utm_source);
    //     setErrorMsg("Something went wrong, Please try again.");
    //     setIsLoader(false);
    //   }
    // }
  };

  const subscriberUser = async (apiName, otp) => {
    let data = {
      msisdn: state.phoneNumber,
      nick: "GW-guestUser",
      otp: otp,
      utm_source: state.utm_source,
      subPackage: state.userPackage,
      otpId: otpId,
      gameId: null,
    };

    try {
      let response = await fetchPost(apiName, userContext.token, data);
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("signup", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("signup", "econceptions");
        } else {
          tagManagerEvents("signup", state.utm_source);
        }
        if (state.exists) {
          login();
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(state.phoneNumber);
        url = `${url}/?user=${encodedMsisdn}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
        setIsLoader(false);
      } else {
        tagManagerEvents("signupFailed", state.utm_source);
        setErrorMsg("Unable to subscribe user, Please try again.");
        setIsLoader(false);
      }
    } catch (err) {
      tagManagerEvents("signupFailed", state.utm_source);
      setErrorMsg("Something went wrong, Please try again.");
      setIsLoader(false);
    }
  };

  const login = async () => {
    let data = {
      msisdn: state.phoneNumber,
    };
    try {
      let response = await fetchPost(
        ApiNames.loginWeb,
        userContext.token,
        data
      );

      // if (response.status === 0) {
      //   let url = window.location.origin;
      //   let encodedMsisdn = base64_encode(state.phoneNumber);
      //   url = `${url}/home?user=${encodedMsisdn}`;
      //   if (url.includes("http:")) {
      //     url = url.replace("http", "https");
      //   }
      //   window.location.replace(url);
      //   setIsLoader(false);
      // } else {
      //   setErrorMsg("Unable to subscribe user, Please try again.");
      //   setIsLoader(false);
      // }
    } catch (err) {
      setErrorMsg("Something went wrong, Please try again.");
      setIsLoader(false);
    }
  };

  const sendOTP = async () => {
    let data = {
      msisdn: state.phoneNumber,
      sourceInfo: "",
    };
    try {
      setLoader(true);
      let response = await fetchPost(ApiNames.signup, userContext.token, data);
      if (response.status === 0) {
        // setOtpId(response.otpId);
        otpId = response.otpId;
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (err) {
      setLoader(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const onResendOtpPressed = () => {
    tagManagerEvents("resendOTP", state.utm_source);
    setSeconds(30);
    setShowCounter(true);
    setOTP("");
    sendOTP();
  };

  return (
    <>
      {state.parameter === "10K" ? (
        <LandingtwoOtp
          OTP={OTP}
          setOTP={setOTP}
          handleChange={handleChange}
          onPressNext={onPressNext}
          isloader={isloader}
          onResendOtpPressed={onResendOtpPressed}
          loader={loader}
          errorMsg={errorMsg}
          seconds={seconds}
          showCounter={showCounter}
        />
      ) : (
        <LandigOtp
          OTP={OTP}
          setOTP={setOTP}
          handleChange={handleChange}
          onPressNext={onPressNext}
          isloader={isloader}
          onResendOtpPressed={onResendOtpPressed}
          loader={loader}
          errorMsg={errorMsg}
          seconds={seconds}
          showCounter={showCounter}
          smartWatchImg={smartWatchImg}
          parameters={state.parameter}
        />
      )}
    </>
  );
};

export default LandigOtpScreen;
