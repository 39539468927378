import React, { useContext, useEffect, useState } from "react";
import GlobalSearch from "../Components/GlobalSearch";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import UserContext from "../Utils/User_Context";
import { useLocation, useNavigate } from "react-router-dom";

let concatArr = [];
let concatArrLimited = [];
let SteamersArrLimited = [];
let gameArrLimited = [];

const GlobalSearchScreen = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchResult, setSearchResult] = useState(null);
  const [tabIndex, setTabIndex] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [subIndex, setSubIndex] = useState(null);
  const [reLoader, setReloader] = useState(false);
  const [loader, setloader] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [msisdn, setMsisdn] = useState();
  const [SteamersFilteredData, setSteamersFilteredData] = useState([]);

  useEffect(() => {
    setSearchResult(null);
    concatArr = [];
    concatArrLimited = [];
    SteamersArrLimited = [];
  }, []);

  useEffect(() => {
    if (state) {
      setInputValue(state?.tag);
      onhandleSearch(state.tag);
    }
  }, [state]);

  useEffect(() => {
    setErrMsg(" ");
    // if (userContext.token) {
    //   onhandleSearch(inputValue);
    // }
    if (reLoader === true) {
      handleListLoaderforFilter();
      setReloader(false);
    }
  }, [reLoader]);

  useEffect(() => {
    if (userContext.token) {
      setMsisdn(localStorage.getItem("msisdn"));
    }
  }, [userContext.token]);

  const searchHandler = (val) => {
    setInputValue(val);
    if (val.length === 0) {
      setSearchResult(null);
    }
  };
  const searchEventHandler = () => {
    onhandleSearch(inputValue);
  };
  const searchEventHandlerEnter = (event) => {
    if (event.key === "Enter") {
      onhandleSearch(inputValue);
    }
  };
  const onhandleSearch = async (val) => {
    setloader(true);

    let endPoint = ApiNames.searchTop + `/${val}?msisdn=${msisdn}`;
    console.log(endPoint);
    try {
      let response = await fetchGet(endPoint, userContext.token);

      if (response.status === "0") {
        if (response.LiveStreams.length > 0 || response.videos.length > 0) {
          concatArr = response.LiveStreams.concat(response.videos);
          concatArrLimited = concatArr.slice(0, 4);
        }
        if (response.streamers.length > 0) {
          let arr = response.streamers;

          SteamersArrLimited = arr.slice(0, 4);
          setSteamersFilteredData(response.streamers);
        }
        if (response.games.length > 0) {
          let arr = response.games;
          gameArrLimited = arr.slice(0, 4);
        }
        setSearchResult(response);
        setloader(false);
      }
    } catch (err) {
      console.log(err);
      setloader(false);
    }
  };

  const onhandelUnsub = async (element, index) => {
    setSubIndex(index);
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: element,
      };
      try {
        let response = await fetchPost(
          ApiNames.streamerUnsubscribe,
          userContext.token,
          data
        );

        if (response.status === 0) {
          setReloader(!reLoader);
          setVideoIndex(index);
        } else {
          setErrMsg(response.result);
        }
      } catch (err) {
        setErrMsg("Something Went Wrong..!");
      }
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };
  const onhandelSub = async (element, index) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: element,
      };
      try {
        setSubIndex(index);
        let response = await fetchPost(
          ApiNames.streamerSubscribe,
          userContext.token,
          data
        );

        if (response.status === 0) {
          setReloader(!reLoader);
          setVideoIndex(index);
          setErrMsg(response.result);
        } else {
          setErrMsg(response.result);
        }
      } catch (err) {
        setErrMsg("Something Went Wrong..!");
      }
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };

  const onHandleNavigate = (index) => {
    navigate("/video?videoId=" + concatArr[index].id, {
      state: {
        data: concatArr[index],
        allData: concatArr,
        type: "mp4",
      },
    });
  };

  const navigateGameDetail = (data) => {
    navigate("/gameinnerpage?gameId=" + data.id, {
      state: {
        data: data,
      },
    });
  };

  const onHandelTagSearch = (tag) => {
    setInputValue(tag);
    onhandleSearch(tag);
  };

  // const handleListLoaderforFilter = () => {
  //   console.log(SteamersArrLimited);
  //   let temp = SteamersArrLimited;
  //   if (SteamersArrLimited.length > 0) {
  //     if (temp[videoIndex].isChannelSubscribed === true) {
  //       temp[videoIndex].isChannelSubscribed = false;
  //       SteamersArrLimited = temp;
  //     } else {
  //       SteamersArrLimited[videoIndex].isChannelSubscribed = true;
  //       SteamersArrLimited = temp;
  //     }
  //   }
  // };
  const handleListLoaderforFilter = () => {
    if (SteamersFilteredData.length > 0) {
      if (SteamersFilteredData[subIndex].isChannelSubscribed === true) {
        console.log(SteamersFilteredData[subIndex].isChannelSubscribed);
        SteamersFilteredData[subIndex].isChannelSubscribed = false;
      } else {
        SteamersFilteredData[subIndex].isChannelSubscribed = true;
      }
      setSteamersFilteredData(SteamersFilteredData);
    }
  };

  return (
    <div>
      <GlobalSearch
        setTabIndex={setTabIndex}
        inputValue={inputValue}
        searchHandler={searchHandler}
        tabIndex={tabIndex}
        searchEventHandler={searchEventHandler}
        searchEventHandlerEnter={searchEventHandlerEnter}
        onhandelUnsub={onhandelUnsub}
        onhandelSub={onhandelSub}
        errMsg={errMsg}
        subIndex={subIndex}
        searchResult={searchResult}
        onHandleNavigate={onHandleNavigate}
        concatArr={concatArr}
        concatArrLimited={concatArrLimited}
        SteamersArrLimited={SteamersFilteredData}
        gameArrLimited={gameArrLimited}
        navigateGameDetail={navigateGameDetail}
        onHandelTagSearch={onHandelTagSearch}
        loader={loader}
      />
    </div>
  );
};

export default GlobalSearchScreen;
