import React from "react";
import "../style/subscription.css";
import Logo from "../Assets/Images/GW-logo.webp";
import OTPInput, { ResendOTP } from "otp-input-react";
// import playwinrepeat from "../Assets/Images/play-win-repeat.webp";

function NewLandingPage(props) {
  function keyFunction(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const validChars = /^[0-9]+$/;

    if (!validChars.test(keyValue)) {
      event.preventDefault();
    }
  }
  return (
    <>
      <div className="px-0 subs-screen">
        <div className="animated-bg">
          <div className="lines">
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 23 }}></span>
            <span style={{ "--i": 11 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 22 }}></span>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 23 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 12 }}></span>
            <span style={{ "--i": 16 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 25 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 21 }}></span>
            <span style={{ "--i": 26 }}></span>
            <span style={{ "--i": 17 }}></span>
            <span style={{ "--i": 13 }}></span>
            <span style={{ "--i": 20 }}></span>
            <span style={{ "--i": 28 }}></span>
            <span style={{ "--i": 23 }}></span>
            <span style={{ "--i": 18 }}></span>
            <span style={{ "--i": 12 }}></span>
          </div>

          <div className="otpouterDiv outerDivMobile">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="logo paralogo">
                    <img src={Logo} />
                  </div>

                  {/* <Navbar /> */}

                  {/* ------------------------------- Enter Ph number -------------------------------  */}
                  <div className="newParamWraper">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pwgImg">
                          {/* <img src={playwinrepeat} width={377} height={358} /> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="subOuterbox">
                          <div className="subscribeBox">
                            <div
                              className="round-gradient"
                              style={{ height: "96.5%" }}
                            ></div>
                            <div className="subinnerBox newparam">
                              <div className="subscriptionText">
                                {/* <h3>
                                  Subscribe &amp; Win <br />
                                  Rs 10,000
                                </h3> */}
                                <p className="getotp">
                                  Please input number to get OTP
                                </p>
                              </div>

                              <div
                                action=""
                                onSubmit={
                                  props.phNumber
                                    ? props.SignUp
                                    : (e) => e.preventDefault()
                                }
                              >
                                <div>
                                  <div className="otpinput-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        +92
                                      </span>
                                    </div>
                                    <input
                                      type="tel"
                                      autoComplete="off"
                                      className="cform-control rounded tel-field"
                                      style={{ color: "white" }}
                                      placeholder="XXX - XXX - XXXX"
                                      value={props.phoneNo}
                                      onChange={props.onTextChange}
                                      maxLength="10"
                                      onKeyPress={keyFunction}
                                      pattern="3[0-9]{9}"
                                    />
                                  </div>
                                  {props.errorMsg && (
                                    <p className="subs-error">
                                      {props.errorMsg}
                                    </p>
                                  )}
                                </div>
                                {/* otp */}

                                {props.openOtpFlag ? (
                                  <>
                                    <div className="subscriptionText">
                                      <p className="getotp">Enter OTP</p>
                                    </div>

                                    <div className="otpInput">
                                      <OTPInput
                                        className="input-fields"
                                        value={props.OTP}
                                        onChange={(value) =>
                                          props.handleChange(value)
                                        }
                                        autoFocus
                                        OTPLength={4}
                                        otpType="number"
                                        disabled={false}
                                      />
                                      {props.errorMsg && (
                                        <p className="subs-error">
                                          {props.errorMsg}
                                        </p>
                                      )}
                                    </div>
                                    <div className="input-group-append">
                                      {props.isloader === false ? (
                                        <button
                                          className="otpBtn confirmbtn"
                                          id="button-addon2"
                                          onClick={() =>
                                            props.onPressNext(null)
                                          }
                                        >
                                          SUBSCRIBE
                                        </button>
                                      ) : (
                                        <button
                                          className="otpBtn"
                                          id="button-addon2"
                                        >
                                          <div className="spinner-container-small">
                                            <div className="loading-spinner-small"></div>
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                    <div>
                                      <div className="resend-otp">
                                        {props.showCounter ? (
                                          <p className="">
                                            <div
                                              className="m-flex"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span> 00:{props.seconds}</span>
                                            </div>
                                            Didn’t Receive OTP?
                                            <span style={{ color: "#C4C4C4" }}>
                                              Resend
                                            </span>
                                          </p>
                                        ) : (
                                          <p>
                                            <div
                                              className="m-flex"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            ></div>
                                            Didn’t Receive OTP?
                                            <span
                                              color="#fff"
                                              onClick={props.onResendOtpPressed}
                                            >
                                              Resend
                                            </span>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <div className="resend-otp">
                                              <p>
                                            <div
                                              className="m-flex"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            ></div>
                                            Didn’t Receive OTP?
                                        <span style={{ color: "#C4C4C4" }}>
                                              Resend
                                            </span>
                                          </p>
                                    
                                      </div>
                                    </div>
                                  </>
                                )}

                                {/* resend */}
                              </div>

                              <p className="taxpermonth">
                                Service Charges: Up to 10 PKRs/Day
                              </p>

                              <div className="links">
                                <a href="/privacy-policy" target="_blank">
                                  Privacy Policy
                                </a>
                                <span></span>
                                <a href="/terms-condition" target="_blank">
                                  Terms &amp; Conditions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewLandingPage;
