import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyCSOqBkgjeSf2vpbwGsKTlJHui6BeMpkTY",
  authDomain: "jazz-game-world.firebaseapp.com",
  projectId: "jazz-game-world",
  storageBucket: "jazz-game-world.appspot.com",
  messagingSenderId: "711086714950",
  appId: "1:711086714950:web:f1bfeb46ae900df8341a47",
  measurementId: "G-6NN3LEWY1S"
};
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
