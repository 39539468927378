import React from "react";
// import Navbar from "./Navbar";
import "../style/subscription.css";
import Logo from "../Assets/Images/landing-logo.webp";
import cardImg from "../Assets/Images/main-img.webp";
import vr from "../Assets/Images/vr.webp";
import gamecontroller from "../Assets/Images/game-controller.webp";
import gamecharacters from "../Assets/Images/game-characters.webp";
import mbmainimg from "../Assets/Images/mb-main-img.webp";

const Landing = (props) => {
  function keyFunction(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const validChars = /^[0-9]+$/;

    if (!validChars.test(keyValue)) {
      event.preventDefault();
    }
  }
  return (
    <>
      {props.isModel ? (
        <div className="lp-content">
          <div className="c-div">
            <img
              fetchpriority="high"
              width="137"
              height="73"
              src={Logo}
              alt="logo"
              style={{
                backgroundColor: "black",
                borderRadius: "10px",
                padding: "8px",
              }}
            />
            <div className="title">
              Unlock a World of <span>Adventure, Entertainment and Rewards</span> at Game
              Club: Where Every Game is an <span>Epic Journey</span>
            </div>
            <button onClick={() => props.onHandleJoinNow()}>Join Now</button>
          </div>
        </div>
      ) : (
        <div className="px-0 subs-screen">
          <div className="animated-bg">
            <div className="otpouterDiv outerDivMobile">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="logo paralogo">
                      <img src={Logo} />
                    </div>

                    {/* <Navbar /> */}

                    {/* ------------------------------- Enter Ph number -------------------------------  */}

                    <div className="newParamWraper">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="subOuterbox">
                            <div className="dis-mobile">
                              <img src={mbmainimg} alt="" />
                            </div>

                            <div className="subscribeBox">
                              <div className="absoluteImgs">
                                <img
                                  src={gamecharacters}
                                  className="gamecharacters"
                                  alt="img"
                                />
                                <img
                                  src={gamecontroller}
                                  className="gamecontroller"
                                  alt="img"
                                />
                                <img src={vr} className="vr-img" alt="img" />
                              </div>

                              <div
                                className="round-gradient"
                                style={{ height: "96.5%" }}
                              ></div>
                              <div className="subinnerBox newparam">
                                <div className="subscriptionText">
                                  {/* <h3>
                                Subscribe &amp; Win <br />
                                Rs 10,000
                              </h3> */}

                                  <div className="cardImg">
                                    <img src={cardImg} alt="main img" />
                                  </div>

                                  <p className="getotp">
                                    Please Enter Phone Number
                                  </p>
                                </div>

                                <div
                                  action=""
                                  onSubmit={
                                    props.phNumber
                                      ? props.SignUp
                                      : (e) => e.preventDefault()
                                  }
                                >
                                  <div>
                                    <div className="otpinput-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          +92
                                        </span>
                                      </div>
                                      <input
                                        type="tel"
                                        autoComplete="off"
                                        className="cform-control rounded tel-field"
                                        style={{ color: "white" }}
                                        placeholder="XXX - XXX - XXXX"
                                        value={props.phoneNo}
                                        onChange={props.onTextChange}
                                        maxLength="10"
                                        onKeyPress={keyFunction}
                                        pattern="3[0-9]{9}"
                                      />
                                    </div>
                                    {props.errorMsg && (
                                      <p className="subs-error">
                                        {props.errorMsg}
                                      </p>
                                    )}
                                  </div>

                                  <div className="input-group-append">
                                    {props.isloader === false ? (
                                      <button
                                        className="otpBtn"
                                        type="submit"
                                        id="button-addon2"
                                        onClick={() => props.onNextPressed()}
                                      >
                                        Get Otp
                                      </button>
                                    ) : (
                                      <button
                                        className="otpBtn"
                                        type="submit"
                                        id="button-addon2"
                                      >
                                        <div className="spinner-container-small">
                                          <div className="loading-spinner-small"></div>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <p className="taxpermonth">
                                  Service Charges: Up to 10 PKRs/Day
                                  {/* * Only for Rs. 3.5 + Tax/Day (Prepaid) / & Rs. 35.85 +{" "}
                        <br /> Tax/Month (Postpaid) * */}
                                </p>

                                <div className="links">
                                  <a href="/privacy-policy" target="_blank">
                                    Privacy Policy
                                  </a>
                                  <span></span>
                                  <a href="/terms-condition" target="_blank">
                                    Terms &amp; Conditions
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Landing;
