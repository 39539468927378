import React from 'react'
import desktopImage from '../Assets/Images/desktop-home.webp'
import mobileImage from "../Assets/Images/mobile-home.webp";

function NewHome() {
  return (
    <div>
      <img src={desktopImage} className="dis-desk" alt="img" style={{width:"100%"}} />
      <img src={mobileImage} className="dis-mobile" alt="img" style={{width:"100%"}} />
    </div>
  );
}

export default NewHome
