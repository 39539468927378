import React, { useState, useEffect } from "react";
// import logo_web_landing from "../Assets/Images/logo_web_landing.webp";
import colock_regular from "../Assets/Images/clock-regular.svg";
// import newlanding from "../Assets/Images/new-landing.webp";
import OTPInput, { ResendOTP } from "otp-input-react";
import "../style/Newlanding.css";
function LandingtwoOtp(props) {
  return (
    <div>
      <div>
        <div class="landing-header">
          <div class="container">
            <a href="java" class="landing-header-logo">
              {/* <img src={logo_web_landing} alt="" /> */}
            </a>
          </div>
        </div>
        <div className="landingWraper">
          <div className="landing-box">
            <div className="inner-box">
              {/* <img src={newlanding} alt="" /> */}
            </div>
            <div className="form-wraper otpformwraper">
              <p className="e-m-n">Enter OTP</p>
              <div className="otpInput">
                <OTPInput
                  className="input-fields"
                  value={props.OTP}
                  onChange={(value) => props.handleChange(value)}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                />
                {props.errorMsg && (
                  <p className="subs-error">{props.errorMsg}</p>
                )}
              </div>
              <div className="resend-otp">
                {props.showCounter ? (
                  <p className="m-text">
                    {" "}
                    Didn’t Receive OTP?{" "}
                    <div className="m-flex">
                      {" "}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="#fff"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M12 6L12 12 16 14"></path>
                        </svg>
                      </div>{" "}
                      <span className="otpseconds"> 00:{props.seconds}</span>
                    </div>
                  </p>
                ) : (
                  <p>
                    {" "}
                    Didn’t Receive OTP?{" "}
                    <span onClick={() => props.onResendOtpPressed()}>
                      Resend
                    </span>
                  </p>
                )}
              </div>
              <div className="form-btn">
                {props.isloader === false ? (
                  <button onClick={() => props.onPressNext(null)}>
                    Subscribe/Login
                  </button>
                ) : (
                  <button>
                    <div className="spinner-container-small">
                      <div className="loading-spinner-small"></div>
                    </div>
                  </button>
                )}
              </div>
              <div className="short-text">
                <p>Service Charges: Up to 10 PKRs/Day</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingtwoOtp;
