import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import tournamentsbanner from "../Assets/Images/tournaments-banner.webp";
import tournamentsbannermobile from "../Assets/Images/tournaments-banner-mobile.webp";

const Tournaments = () => {
  return (
    <div>
      <Navbar />
      <div className="tournaments-banner">
        <img
          src={tournamentsbanner}
          alt="tournaments banner"
          className="dis-desk"
        />
        <img
          src={tournamentsbannermobile}
          alt="tournaments banner"
          className="dis-mobile"
        />
      </div>
      <div className="gameDescWraper">
        <div style={{ minHeight: "100px" }}></div>
        <Footer />
      </div>
    </div>
  );
};

export default Tournaments;
