import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import "../style/GamePage.css";
import "../style/commonStyle.css";
import gamepagebanner from "../Assets/Images/games-page-banner.webp";
import gaming_joysticl from "../Assets/Images/gaming_joysticl.webp";
import downloadgames from "../Assets/Images/downloadgames.webp";
import playinstangames from "../Assets/Images/playinstangames.webp";
import banner_bg_two_mob from "../Assets/Images/banner_bg_two_mob.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import LoaderSpiner from "./LoaderSpiner";
import UserContext from "../Utils/User_Context";
import { ImgUrl } from "../Utils/FetchApi";
import gametypeicon from "../Assets/Images/game-type-icon.webp";

let taglist = [];
let tagArr = [];

const Game = (props) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const handleSearchClick = () => {
    if (window.innerWidth <= 767) {
      const input = document.getElementById("searchInput");
      const searchButton = document.getElementById("searchButton");
      const cancelButton = document.getElementById("cancelButton");

      input.style.display = "block";
      searchButton.style.display = "none";
      cancelButton.style.display = "inline-block";
    }
  };

  const handleCancelClick = () => {
    if (window.innerWidth <= 767) {
      const input = document.getElementById("searchInput");
      const searchButton = document.getElementById("searchButton");
      const cancelButton = document.getElementById("cancelButton");

      input.value = ""; // Clear the input value
      input.style.display = "none";
      searchButton.style.display = "inline-block";
      cancelButton.style.display = "none";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* {props.isLoading ? (
        <LoaderSpiner />
      ) : ( */}
      <div className="px-0">
        <div className="container">
          <Navbar />
        </div>
        <div className="game-page-banner">
          <img src={gamepagebanner} alt="" className="dis-desk" />
          <img src={banner_bg_two_mob} alt="" className="dis-mobile" />
          <div className="banner__Wraper">
            <h1 className="mainHead dis-desk">
              Explore<br></br> Popular Games
            </h1>
            <p className="shortText dis-desk">
              Get Hold Of Top Mobile & Web Games Ranging From
              <br />
              Action To Puzzles
            </p>
            <div className="bannerBtns gamePagebannerBtn">
              <button
                type="button"
                className="d-btn"
                onClick={() => {
                  props.setTabIndex(1);
                  props.TabSelector(1);
                  window.scrollTo(0, 720);
                }}
              >
                Play Game
              </button>
              <button
                type="button"
                className="d-btn dark-d-btn"
                onClick={() => {
                  props.setTabIndex(2);
                  props.TabSelector(2);
                  window.scrollTo(0, 720);
                }}
              >
                Download Game
              </button>
            </div>
          </div>
        </div>
        <div className="games-page-bg">
          <div className="gamestabs">
            <div className="container">
              <div className="gc-tab-btn">
                <button
                  className={props.tabIndex === 1 ? "active" : ""}
                  onClick={() => {
                    props.setTabIndex(1);
                    props.TabSelector(1);
                  }}
                >
                  <img src={gaming_joysticl} alt="joystick" />
                  Play Instant Game
                </button>
                <button
                  className={props.tabIndex === 2 ? "active" : ""}
                  onClick={() => {
                    props.setTabIndex(2);
                    props.TabSelector(2);
                  }}
                >
                  <img src={downloadgames} alt="" />
                  Mobile Games
                </button>
                <button
                  className={props.tabIndex === 3 ? "active" : ""}
                  onClick={() => {
                    props.setTabIndex(3);
                    props.TabSelector(3);
                  }}
                >
                  <img src={playinstangames} alt="" />
                  Multiplayer Game
                </button>
                {/* 
                <div className="tabsSearchBtn">
                  <div className="searchIcon">
                    <input
                      type="text"
                      value={props.inputValue}
                      placeholder="Search"
                      id="searchInput"
                      onChange={(e) => props.searchHandler(e.target.value)}
                      maxLength={10}
                    />
                    <button onClick={handleSearchClick} id="searchButton">
                      <svg
                        stroke="white"
                        strokeWidth="0.3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search header__button-icon iconMobile"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                      </svg>
                    </button>
                    <button
                      style={{ display: "none" }}
                      id="cancelButton"
                      onClick={handleCancelClick}
                    >
                      X
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="gc-gameSearch">
            <div className="container">
                <div className="gc-searchIcon">
                  <input
                    type="text"
                    value={props.inputValue}
                    placeholder="Search"
                    id="searchInput"
                    onChange={(e) => props.searchHandler(e.target.value)}
                    maxLength={10}
                  />
                  <button onClick={handleSearchClick} id="searchButton">
                    <svg
                      stroke="white"
                      strokeWidth="0.3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search header__button-icon iconMobile"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                    </svg>
                  </button>
                  <button
                    style={{ display: "none" }}
                    id="cancelButton"
                    onClick={handleCancelClick}
                  >
                    X
                  </button>
                </div>
            </div>
          </div>
          {/* Data list */}

          {props.isLoading && props.gameDataList?.length === 0 ? (
            <LoaderSpiner />
          ) : (
            <>
              {props.filteredData.length === 0 && props.inputValue !== "" ? (
                <div className="noDataStyle">No Record Found</div>
              ) : (
                <>
                  {props.filteredData.length === 0 &&
                  props.inputValue === "" ? (
                    <div className="padding-container">
                      <Container>
                        <Row xs={1} sm={2} md={2} lg={4} className="g-4 ">
                          {props.gameDataList ? (
                            <>
                              {props.gameDataList.map((data, index) => {
                                taglist = data.tag;
                                tagArr =  taglist?.split(",");
                                return (
                                  <Col key={index}>
                                    <Card className="hp-card">
                                      <div style={{ overflow: "hidden" }}>
                                        <Card.Img
                                          onClick={() =>
                                            props.navigateGameDetail(data)
                                          }
                                          variant="top"
                                          src={`${ImgUrl}${data.image}`}
                                          className="game-card-Img"
                                        />
                                      </div>

                                      <Card.Body className="tag-body">
                                        <div className="flex">
                                          <Card.Title className="gc-title">
                                            {data.title}
                                          </Card.Title>
                                          {/* <button type="button" className="d-btn  btnMobile  cardGamBtnMrktPlace ">BUY NOW</button> */}
                                          {/* <div className="">
                                            {props.tabIndex === 1 ? (
                                              <>
                                                <button
                                                  type="button"
                                                  className="d-btn "
                                                  onClick={() =>
                                                    props.PlayNow(
                                                      data.webGameUrl
                                                    )
                                                  }
                                                >
                                                  Play Now
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                {userContext.msisdn ? (
                                                  <a
                                                    onClick={() =>
                                                      props.Download(data.id)
                                                    }
                                                    href={`${ImgUrl}${data.url}`}
                                                    download
                                                  >
                                                    <button
                                                      type="button"
                                                      className="d-btn"
                                                    >
                                                      Download
                                                    </button>
                                                  </a>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    className="d-btn "
                                                    onClick={() =>
                                                      navigate("/landing")
                                                    }
                                                  >
                                                    Download
                                                  </button>
                                                )}
                                              </>
                                            )}
                                          </div> */}
                                        </div>
                                        {/* <Card.Text>{data.description}</Card.Text>
                                                <Card.Text>{data.viewsCount}</Card.Text> */}
                                        <div className="shootActionBtn">
                                          {
                                            <button
                                              onClick={() =>
                                                props.onHandelTagSearch(tagArr[0])
                                              }
                                              className="gc-tagbtn"
                                            >
                                              <img
                                                src={gametypeicon}
                                                alt="tag btn"
                                              />
                                              {tagArr[0]}
                                            </button>
                                          }
                                          {/* {tagArr?.map((tag, index) => {
                                            return (
                                              <button
                                                onClick={() =>
                                                  props.onHandelTagSearch(tag)
                                                }
                                                className="gc-tagbtn"
                                              >
                                                <img
                                                  src={gametypeicon}
                                                  alt="tag btn"
                                                />
                                                {tag}
                                              </button>
                                            );
                                          })} */}
                                        </div>
                                        {/* <button className='cardBtnLvlUp py-1 d-none d-sm-block d-inline'>Level UP</button> */}
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                );
                              })}
                            </>
                          ) : (
                            <div className="noDataStyle">No Record Found</div>
                          )}
                        </Row>
                      </Container>
                    </div>
                  ) : (
                    <div className="padding-container">
                      <Container>
                        <Row xs={1} sm={2} md={2} lg={4} className="g-4">
                          {props.filteredData.map((data, index) => {
                            taglist = data.tag;
                            tagArr = taglist?.split(",");
                            return (
                              <Col key={index}>
                                <Card className="hp-card">
                                  <div style={{ overflow: "hidden" }}>
                                    <Card.Img
                                      onClick={() =>
                                        props.navigateGameDetail(data)
                                      }
                                      variant="top"
                                      src={`${ImgUrl}${data.image}`}
                                      className="game-card-Img"
                                    />
                                  </div>

                                  <Card.Body className="tag-body">
                                    <div className="flex">
                                      <Card.Title className="gc-title">
                                        {data.title}
                                      </Card.Title>
                                    </div>
                                    {/* <Card.Text>{data.description}</Card.Text>
                                                <Card.Text>{data.viewsCount}</Card.Text> */}
                                    <div className="shootActionBtn">
                                      {tagArr?.map((tag) => {
                                        return (
                                          <button
                                            onClick={() =>
                                              props.onHandelTagSearch(tag)
                                            }
                                            className="gc-tagbtn"
                                          >
                                            <img
                                              src={gametypeicon}
                                              alt="tag btn"
                                            />
                                            {tag}
                                          </button>
                                        );
                                      })}
                                    </div>
                                    {/* <button className='cardBtnLvlUp py-1 d-none d-sm-block d-inline'>Level UP</button> */}

                                    {/* <div className="d-block d-sm-block d-md-none">
                                      {props.tabIndex === 1 ? (
                                        <button
                                          type="button"
                                          className="d-btn "
                                          onClick={() =>
                                            props.PlayNow(data.webGameUrl)
                                          }
                                        >
                                          Play Now
                                        </button>
                                      ) : (
                                        <>
                                          {userContext.msisdn ? (
                                            <a
                                              onClick={() =>
                                                props.Download(data.id)
                                              }
                                              href={`${ImgUrl}${data.url}`}
                                              download
                                            >
                                              <button
                                                type="button"
                                                className="d-btn  "
                                              >
                                                Download
                                              </button>
                                            </a>
                                          ) : (
                                            <button
                                              type="button"
                                              className="d-btn  "
                                              onClick={() =>
                                                navigate("/landing")
                                              }
                                            >
                                              Download
                                            </button>
                                          )}
                                        </>
                                      )}
                                    </div> */}
                                  </Card.Body>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </Container>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {/* -------------------------------------------- Footer Section -------------------------------------------- */}

          {/* footer section                                      */}
          <Footer />
          {/* footer section                                      */}
        </div>
      </div>
      {/* // )} */}
    </>
  );
};

export default Game;
