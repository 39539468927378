import React, { useEffect } from "react";
import Footer from "./Footer";
import "../style/videoStreamLive.css";
import Navbar from "./Navbar";
import ReactHlsPlayer from "react-hls-player";
import LoaderSpiner from "./LoaderSpiner";
import { ImgUrl } from "../Utils/FetchApi";

function VideoLiveStream(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="px-0">
      <div className="VideoouterDiv">
        <div className="container">
          <Navbar />
        </div>

        <div className="videoStreamSection">
          <div className="container">
            {props.videoUrl ? (
              <div className="row">
                <div className="col-md-8">
                  <div className="vid__Streamer">
                    {/* {props.videoUrl ? ( */}
                    {/* <> */}
                    {props.videoUrl.includes(".mp4") ? (
                      <video
                        key={props.streamData.id}
                        controls
                        controlsList="nodownload"
                        autoPlay
                      >
                        <source src={props.videoUrl} type="video/mp4" />
                      </video>
                    ) : (
                      <ReactHlsPlayer
                        src={props.videoUrl}
                        autoPlay={true}
                        controls={true}
                        width="100%"
                        height="auto"
                      />
                    )}
                  </div>
                  {props?.streamData ? (
                    <div className="streamerProfile">
                      <div className="userDetail">
                        <div className="userImg">
                          {props.streamData.userImage ? (
                            <img
                              src={`${ImgUrl}${props.streamData.userImage}`}
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${ImgUrl}${props.streamData.picture}`}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="userName">
                          <p className="userLive">
                            {props?.streamData.channelTitle}
                          </p>
                          <p className="userViews"> {props.viewCount} views</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* challanges section */}

                  {props.gameDetail ? (
                    <div className="challengesWraper">
                      <div className="challenges">
                        <img
                          src={`${ImgUrl}/${props.gameDetail.game.image}`}
                          alt=""
                        />
                      </div>

                      <div className="challengesContent">
                        {props.gameDetail.game.description === null ? null : (
                          <p>{props.gameDetail.game.description}</p>
                        )}
                        <span>{props.gameDetail.game.title}</span>
                        <div className="chabtns">
                          <button
                            type="button"
                            className="d-btn  btnMobile "
                            onClick={() => props.onHandleBrowseVideo()}
                          >
                            Browse Game
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* sidebar               */}
                <div className="col-md-4">
                  {/* live chat section added here */}

                  <div className="recommendedStreams">
                    <p className="recomendedHeading">Recommended Streams</p>

                    {/* recomended videos */}
                    <div className="recomendedVideos">
                      {props.streamAllData.map((stream, index) => (
                        <>
                          {props.videoId === stream.id ? null : (
                            <a
                              className="rec__videos"
                              onClick={() =>
                                props.onHandleChangeVideo(stream.id)
                              }
                            >
                              <img
                                src={`${ImgUrl}${stream.videoImage}`}
                                alt=""
                                className="center_videoImg"
                              />
                              <div className="streamerTitle">
                                <p className="streamDesc">{stream.title}</p>
                                <div className="new userDetail">
                                  <div className="new userImg">
                                    <img
                                      src={`${ImgUrl}${stream.userImage}`}
                                      alt=""
                                    />
                                  </div>
                                  <div className="userName">
                                    <p className="new userLive">
                                      {stream.channelTitle}
                                    </p>
                                    <p className="new userViews">
                                      {stream.viewsCount} views
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <LoaderSpiner />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default VideoLiveStream;
