import React from 'react'
const LoaderSpiner = () => {
  return (
    <div className="spinner-container">
    <div className="loading-spinner">
    </div>
  </div>
  )
}

export default LoaderSpiner