import Swal from "sweetalert2";
import React, { useState, useEffect, useContext } from "react";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import UserContext from "../Utils/User_Context";
import { useNavigate } from "react-router-dom";

function StreamersScreen(props) {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [streamData, setStreamData] = useState([]);
  const [isLoader, setIsloader] = useState(false);
  const [reLoader, setReloader] = useState(false);

  useEffect(() => {
    if (userContext.token) {
      if (userContext.msisdn) {
        handelStreamersList(ApiNames.streamerPopular + userContext.msisdn);
      } else {
        handelStreamersList(ApiNames.streamerPopular);
      }
    }
  }, [userContext.token, reLoader]);

  const handelStreamersList = async (ApiName) => {
    setIsloader(true);
    try {
      let response = await fetchGet(ApiName, userContext.token);
      if (response.status === "0") {
        setStreamData(response.streamers);
        props.setStreamersData(response.streamers);
        setIsloader(false);
      } else {
        setIsloader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
      }
    } catch (err) {
      setIsloader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong...!",
      });
    }
  };

  const onhandelUnsub = async (element) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: "3045602206",
        resourceId: element,
      };
      try {
        let response = await fetchPost(
          ApiNames.streamerUnsubscribe,
          userContext.token,
          data
        );
        if (response.status === 0) {
          setReloader(!reLoader);
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.result,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.result,
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong..!",
        });
      }
    } else {
      navigate("/");
    }
  };
  const onhandelSub = async (element) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: "3045602206",
        resourceId: element,
      };
      try {
        let response = await fetchPost(
          ApiNames.streamerSubscribe,
          userContext.token,
          data
        );
        if (response.status === 0) {
          setReloader(!reLoader);
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.result,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.result,
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong..!",
        });
      }
    } else {
      navigate("/");
    }
  };

  return (
    <>
      {/* <Streamers
        inputValue={props.inputValue}
        streamData={streamData}
        SteamersFilteredData={props.SteamersFilteredData}
        isLoader={isLoader}
        onhandelUnsub={onhandelUnsub}
        onhandelSub={onhandelSub}
      /> */}
    </>
  );
}

export default StreamersScreen;
