import React, { useContext, useEffect, useState } from "react";
import UserContext from "../Utils/User_Context";
import UserProfile from "../Components/UserProfile";
import ApiNames from "../Constants/ApiNames";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import { useLocation, useNavigate } from "react-router-dom";

function UserProfileScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [streamerDetail, setStreamerDetail] = useState();
  const [streamerProfile, setSteamerProfile] = useState();
  const [channelSubscrib, setchannelSubscrib] = useState(true);
  const [subscriberCount, setSubscriberCount] = useState(
    location.state.subscriberCount
  );

  useEffect(() => {
    if (userContext.token && location.state.channelId) {
      handleUserDetail();
    }
    if (location.state.isChannelSubscribed) {
      setchannelSubscrib(true);
    } else {
      setchannelSubscrib(false);
    }
  }, [userContext.token]);

  //   Api call
  const handleUserDetail = async () => {
    try {
      let endPoint = ApiNames.streamerVideo + location.state.id;

      let response = await fetchGet(endPoint, userContext.token);
      setSteamerProfile(response.channelInfo);
      setStreamerDetail(response.channelVideos);
    } catch (error) {}
  };

  // function
  const onhandleVideoStream = (index) => {
    navigate("/video?videoId=" + streamerDetail[index].id, {
      state: {
        data: streamerDetail[index],
        allData: streamerDetail,
        type: "mp4",
      },
    });
  };

  const onhandelUnsub = async () => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: location.state.channelId,
      };
      try {
        let response = await fetchPost(
          ApiNames.streamerUnsubscribe,
          userContext.token,
          data
        );

        if (response.status === 0) {
          setchannelSubscrib(false);
          setSubscriberCount(subscriberCount - 1);
        } else {
          setchannelSubscrib(false);
        }
      } catch (err) {}
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };
  const onhandelSub = async () => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: location.state.channelId,
      };
      try {
        let response = await fetchPost(
          ApiNames.streamerSubscribe,
          userContext.token,
          data
        );

        if (response.status === 0) {
          setSubscriberCount(subscriberCount + 1);
          setchannelSubscrib(true);
        } else {
          setchannelSubscrib(true);
        }
      } catch (err) {}
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };
  // const handleListLoaderforFilter = () => {
  //   if (SteamersFilteredData.length > 0) {
  //     if (SteamersFilteredData[subIndex].isChannelSubscribed === true) {
  //       console.log(SteamersFilteredData[subIndex].isChannelSubscribed);
  //       SteamersFilteredData[subIndex].isChannelSubscribed = false;
  //     } else {
  //       SteamersFilteredData[subIndex].isChannelSubscribed = true;
  //     }
  //     setSteamersFilteredData(SteamersFilteredData);
  //   }
  // };

  return (
    <>
      <UserProfile
        streamerDetail={streamerDetail}
        onhandleVideoStream={onhandleVideoStream}
        onhandelSub={onhandelSub}
        onhandelUnsub={onhandelUnsub}
        channelSubscrib={channelSubscrib}
        subscriberCount={subscriberCount}
      />
    </>
  );
}

export default UserProfileScreen;
