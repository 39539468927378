import React, { useState } from "react";
import GW_logo from "../Assets/Images/GW-logo.webp";
import comingsoon from "../Assets/Images/coming-soon.webp";
import { Button, Modal } from "react-bootstrap";
const CommingSoonModal = (props) => {
 
   
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.handleClose}
        centered
        className="cs-Modal"
      >
        <Modal.Header closeButton>
          <img src={GW_logo} alt="" className="CS-Logo" />
          {/* <Modal.Title>Modal Title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <p>Modal body text goes here.</p> */}

          <img src={comingsoon} alt="" className="comingsoonImg" />

          <h2>
            {props.contentValue ? (
              <>
                {" "}
                Don't miss out on the upcoming online tournament - be sure to
                return!
              </>
            ) : (
              <>Coming Soon</>
            )}
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="otpBtn"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommingSoonModal
