import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../style/ScreenShots.css";
import { useNavigate } from "react-router-dom";
import { ImgUrl } from "../Utils/FetchApi";

const GameDetail = (props) => {
  console.log("props.gameDetail:", props.gameDetail);

  const navigate = useNavigate();
  return (
    <div className="px-0">
      <Navbar />
      {props.gameDetail ? (
        <div className="game-inner-page-banner">
          <div className="gameDetailWraper">
            <div className="container">
              <div className="abs-text">
                <img
                  src={`${ImgUrl}${props.gameDetail?.image}`}
                  className="gameDetailImg"
                  alt="Banner Slider"
                />
                <div className="game-absolute-title">
                  {props.gameDetail?.title}
                </div>
              </div>
              <div className="screenShotsWraper">
                <div className="mainHead">{props.gameDetail?.title}</div>
                <div className="">
                  {props.gameDetail?.url ? (
                    <>
                      {props.msisdn ? (
                        <a
                          onClick={() => props.Download(props.gameDetail.id)}
                          href={`${ImgUrl}${props.gameDetail?.url}`}
                          download
                        >
                          <button type="button" className="d-btn">
                            DOWNLOAD GAME
                          </button>
                        </a>
                      ) : (
                        <button
                          type="button"
                          className="d-btn"
                          onClick={() =>
                            window.location.replace(
                              window.location.origin + "/onboarding.html"
                            )
                          }
                        >
                          DOWNLOAD GAME
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      type="button"
                      className="d-btn"
                      onClick={() =>
                        props.PlayNow(props.gameDetail?.webGameUrl)
                      }
                    >
                      PLAY GAME
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="gameDescWraper">
            <div className="container">
              <div className="shotDesc main-padding">
                {props.gameDetail?.description ? (
                  <p className="abt-game">About This Game</p>
                ) : null}

                {props.gameDetail?.description}
              </div>
            </div>
            {/* ----------------------------- Slider ---------------------------- */}
            <Footer />
          </div>
        </div>
      ) : (
        <div className="noDataStyle">No Record Found</div>
      )}
    </div>
  );
};

export default GameDetail;
