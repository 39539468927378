import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../style/streameres.css";
import { ImgUrl } from "../../Utils/FetchApi";
import { useNavigate } from "react-router-dom";

function Streamers(props) {
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <div className="outerDivMobile">
          <div className="container">{/* <Navbar /> */}</div>
          {/* slider end */}
          {props.SteamersFilteredData?.length === 0 &&
          props.inputValue !== "" ? (
            <div className="noDataStyle">No Record Found</div>
          ) : (
            <>
              {props.SteamersFilteredData?.length === 0 ||
              props.SteamersFilteredData === null ? (
                <Container>
                  <Row
                    xs={2}
                    sm={2}
                    md={3}
                    lg={4}
                    className="g-4 mobileCenterLiveStrm mt-2"
                  >
                    {props.streamersData?.map((stream, index) => (
                      <Col key={index}>
                        <Card className="m-stram-card">
                          <Card.Img
                            variant="top"
                            className="str_img"
                            src={`${ImgUrl}${stream.picture}`}
                            onClick={() => {
                              const data = {
                                profilePicture: stream.picture,
                                streamerName: stream.firstName,
                                streamerLastName: stream.lastName,
                                subscriberCount: stream.subscriberCount,
                                isChannelSubscribed: stream.isChannelSubscribed,
                                channelId: stream.channelId,
                                id: stream.id,
                                index: index,
                              };

                              navigate("/userprofile", {
                                state: data,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          />

                          <Card.Body className="LiveStrmBodyFont">
                            <Card.Title
                              className="LiveStrmTitFont"
                              onClick={() => {
                                const data = {
                                  profilePicture: stream.picture,
                                  streamerName: stream.firstName,
                                  streamerLastName: stream.lastName,
                                  subscriberCount: stream.subscriberCount,
                                  isChannelSubscribed:
                                    stream.isChannelSubscribed,
                                  channelId: stream.channelId,
                                  id: stream.id,
                                  index: index,
                                };
                                navigate("/userprofile", {
                                  state: data,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {stream.firstName} {stream.lastName}
                            </Card.Title>
                            <Card.Text
                              className="subscribers"
                              onClick={() => {
                                const data = {
                                  profilePicture: stream.picture,
                                  streamerName: stream.firstName,
                                  streamerLastName: stream.lastName,
                                  subscriberCount: stream.subscriberCount,
                                  isChannelSubscribed:
                                    stream.isChannelSubscribed,
                                  channelId: stream.channelId,
                                  id: stream.id,
                                  index: index,
                                };
                                navigate("/userprofile", {
                                  state: data,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {stream.subscriberCount} Subscribers
                            </Card.Text>
                            <div className="">
                              {stream.isChannelSubscribed === true ? (
                                <button
                                  type="button"
                                  className="downloadBtn d-btn-Down downloadBtnMobile"
                                  onClick={() =>
                                    props.onhandelUnsub(stream.channelId, index)
                                  }
                                >
                                  Unfollow
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="downloadBtn d-btn-Down downloadBtnMobile"
                                  onClick={() =>
                                    props.onhandelSub(stream.channelId, index)
                                  }
                                >
                                  Follow
                                </button>
                              )}
                              {/* {props.subIndex === index ? (
                                <div
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "10px",
                                  }}
                                >
                                  {props.errMsg}
                                </div>
                              ) : (
                                <></>
                              )} */}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Container>
              ) : (
                <Container>
                  <Row
                    xs={2}
                    sm={2}
                    md={3}
                    lg={4}
                    className="g-4 mobileCenterLiveStrm"
                  >
                    {props.SteamersFilteredData?.map((stream, index) => (
                      <Col key={index}>
                        <Card className="m-stram-card">
                          <Card.Img
                            variant="top"
                            className="str_img"
                            src={`${ImgUrl}${stream.picture}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const data = {
                                profilePicture: stream.picture,
                                streamerName: stream.firstName,
                                streamerLastName: stream.lastName,
                                subscriberCount: stream.subscriberCount,
                                isChannelSubscribed: stream.isChannelSubscribed,
                                channelId: stream.channelId,
                                id: stream.id,
                                index: index,
                              };
                              navigate("/userprofile", {
                                state: data,
                              });
                            }}
                          />

                          <Card.Body className="LiveStrmBodyFont">
                            <Card.Title
                              className="LiveStrmTitFont"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const data = {
                                  profilePicture: stream.picture,
                                  streamerName: stream.firstName,
                                  streamerLastName: stream.lastName,
                                  subscriberCount: stream.subscriberCount,
                                  isChannelSubscribed:
                                    stream.isChannelSubscribed,
                                  channelId: stream.channelId,
                                  id: stream.id,
                                  index: index,
                                };
                                navigate("/userprofile", {
                                  state: data,
                                });
                              }}
                            >
                              {stream.firstName} {stream.lastName}
                            </Card.Title>
                            <Card.Text
                              className="subscribers"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const data = {
                                  profilePicture: stream.picture,
                                  streamerName: stream.firstName,
                                  streamerLastName: stream.lastName,
                                  subscriberCount: stream.subscriberCount,
                                  isChannelSubscribed:
                                    stream.isChannelSubscribed,
                                  channelId: stream.channelId,
                                  id: stream.id,
                                  index: index,
                                };
                                navigate("/userprofile", {
                                  state: data,
                                });
                              }}
                            >
                              {stream.subscriberCount} Subscribers
                            </Card.Text>
                            <div className="">
                              {stream.isChannelSubscribed ? (
                                <button
                                  type="button"
                                  className="downloadBtn d-btn-Down downloadBtnMobile"
                                  onClick={() => {
                                    props.onhandelUnsub(
                                      stream.channelId,
                                      index
                                    );

                                    console.log(
                                      "check is channel sub" +
                                        stream.isChannelSubscribed
                                    );
                                  }}
                                >
                                  Unfollow
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="downloadBtn d-btn-Down downloadBtnMobile"
                                  onClick={() => {
                                    props.onhandelSub(stream.channelId, index);

                                    console.log(
                                      "check is channel sub" +
                                        stream.isChannelSubscribed
                                    );
                                  }}
                                >
                                  Follow
                                </button>
                              )}
                              {/* {props.subIndex === index ? (
                                <div
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "10px",
                                  }}
                                >
                                  {props.errMsg}
                                </div>
                              ) : (
                                <></>
                              )} */}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Container>
              )}
            </>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default Streamers;
