import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import "bootstrap/dist/js/bootstrap.bundle";
import homebanner from "../Assets/Images/home-banner.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../style/homepage.css";
import "slick-carousel/slick/slick-theme.css";
// import gameIcon from "../Assets/Images/gameIcon.png";
import marketPlaceImg1 from "../Assets/Images/marketPlaceImg1.webp";
import marketPlaceImg2 from "../Assets/Images/marketPlaceImg2.webp";
import marketPlaceImg3 from "../Assets/Images/marketPlaceImg3.webp";
import marketPlaceImg4 from "../Assets/Images/marketPlaceImg4.webp";
import marketPlaceImg5 from "../Assets/Images/marketPlaceImg5.webp";
import marketPlaceImg6 from "../Assets/Images/marketPlaceImg6.webp";
import marketPlaceImg7 from "../Assets/Images/marketPlaceImg7.webp";
import gametypeicon from "../Assets/Images/game-type-icon.webp";
import heropanelmobile from "../Assets/Images/hero-panel-mobile.webp";

// import Hloaderspinner from "./Hloaderspinner";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Card from "react-bootstrap/Card";
import Navbar from "./Navbar";
import CommingSoonModal from "./CommingSoonModal";
// import ReactHlsPlayer from "react-hls-player";
// import LoaderSpiner from "./LoaderSpiner";
import sliderImg2 from "../Assets/Images/sliderImg2.png";
import { ImgUrl } from "../Utils/FetchApi";

let taglist = [];
let tagArr = [];

const Home = (props) => {

  let marketPlaceData = [
    {
      Img: marketPlaceImg1,
      marketTitle: "XBOX GIFT CARD",
      marketGame: "XBOX",
      marketPrice: "4000",
    },
    {
      Img: marketPlaceImg2,
      marketTitle: "PLAYSTATION GIFT CARD",
      marketGame: "PLAYSTATION",
      marketPrice: "3500",
    },
    {
      Img: marketPlaceImg3,
      marketTitle: "PUBG GIFT CARD",
      marketGame: "PUBG",
      marketPrice: "6000",
    },
    {
      Img: marketPlaceImg4,
      marketTitle: "PLAYSTATION PLUS",
      marketGame: "PLAYSTATION",
      marketPrice: "8000",
    },
    {
      Img: marketPlaceImg5,
      marketTitle: "XBOX GOLD GIFT CARD",
      marketGame: "XBOX",
      marketPrice: "5000",
    },
    {
      Img: marketPlaceImg6,
      marketTitle: "MINECRAFT GIFT CARD",
      marketGame: "MINECRAFT",
      marketPrice: "4500",
    },
    {
      Img: marketPlaceImg7,
      marketTitle: "ROBOX GIFT CARD",
      marketGame: "ROBOX",
      marketPrice: "4500",
    },
  ];

  var settingsInsGames = {
    autoplay: true,
    infinite: true,
    speed: 500,
    centerPadding: "30px",
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  // instant game slider
  var settingsInsGamesone = {
    autoplay: true,
    infinite: true,
    speed: 500,
    centerPadding: "30px",
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <>
      {/* {props.isLoader ? (
        <Hloaderspinner />
      ) : ( */}
      <div className="px-0">
        <div className="container">
          <Navbar />
        </div>
        <div className="GC-banner">
          <img src={homebanner} alt="banner" className="dis-desk" />
          <img
            src={heropanelmobile}
            alt="banner"
            className="dis-mobile mob-hp-banner"
          />
          <div className="explorePopulargame">
            <h2 className="mainHead">Explore</h2>
            <h2 className="mainHead">Popular Games</h2>
            <p className="shortText">
              Grab the most exciting and beloved top mobile and web games,
              spanning a variety of genres from action to puzzles.
            </p>
            <div className="hp-banner">
              {/* <button className="d-btn">Play Game</button> */}
              <Link className="d-btn" aria-current="page" to="/allgames">
                Play Game
              </Link>
              {/* <button className="d-btn dark-d-btn">Download Game</button> */}
              <Link
                className="d-btn dark-d-btn"
                aria-current="page"
                to="/allgames"
              >
                Download Game
              </Link>
            </div>
          </div>
        </div>

        {/* -------------------------------------------- Center Mode SLider -------------------------------------------- */}

        <div className="mainBgSection">
          {/* -------------------------------------------- Instant Games Section -------------------------------------------- */}
          {props.webGameData.length === 0 ? (
            <></>
          ) : (
            <div className="instantGames pd-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="instantGamesTop">
                      <p className="mainHead">Instant Games</p>
                      <p className="shortText">
                        Experience instant fun with games that start right away.
                        Tap on any game for immediate excitement and rapid
                        thrills!
                      </p>
                    </div>
                    <div className="instgame__wraper">
                      <div className="instant__game__slider">
                        <Slider {...settingsInsGamesone} className="">
                          {props.webGameData.map((data, index) => {
                            taglist = data.tag;
                            tagArr = taglist?.split(",");

                            return (
                              <div className="">
                                <Card className="mx-2 hp-card">
                                  <Card.Img
                                    onClick={() =>
                                      props.navigateGameDetail(data)
                                    }
                                    key={index}
                                    variant="top"
                                    src={`${ImgUrl}${data.image}`}
                                    className="game-card-Img"
                                  />
                                  <Card.Body className="tag-body">
                                    <Card.Title>{data.title}</Card.Title>

                                    {/* {tagArr?.map((tag) => {
                                      return (
                                        <button
                                          onClick={() =>
                                            props.onHandelTagSearch(tag)
                                          }
                                          className="gc-tagbtn"
                                        >
                                          <img
                                            src={gametypeicon}
                                            alt="tag btn"
                                          />
                                          {tag}
                                       

                                        </button>
                                      );
                                    })} */}
                                    {
                                      <button
                                        onClick={() =>
                                          props.onHandelTagSearch(tagArr[0])
                                        }
                                        className="gc-tagbtn"
                                      >
                                        <img src={gametypeicon} alt="tag btn" />
                                        {tagArr[0]}
                                      </button>
                                    }
                                  </Card.Body>
                                </Card>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                      <div className="see-all-btn">
                        <Link
                          className="d-btn"
                          aria-current="page"
                          to="/allgames"
                        >
                          See All
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -------------------------------------------- Market Place Section -------------------------------------------- */}

          <div className="gc-marketPlace main-padding">
            <div className="container">
              <div className="marketTop">
                <p className="mainHead">Market Place</p>
                <p className="shortText">
                  Ignite your gaming passion with our extensive collection of
                  gift cards.
                </p>
              </div>
              <div className="row marketswiper">
                <div className="col-md-12 col-12">
                  <Swiper
                    effect={"coverflow"}
                    // grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    autoplay={true}
                    // slidesPerView={'auto'}
                    breakpoints={{
                      767: {
                        slidesPerView: 1,
                      },
                      998: {
                        slidesPerView: 5,
                      },
                      1000: {
                        slidesPerView: 3,
                      },
                    }}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 80,
                      depth: 100,
                      modifier: 2.5,
                    }}
                    // pagination={{ el: '.swiper-pagination', clickable: true }}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                      clickable: true,
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="swiper_container"
                  >
                    {marketPlaceData.map((e) => (
                      <SwiperSlide>
                        <div className="marketCard">
                          <img
                            src={e.Img}
                            alt="slide_image"
                            className="marketImg"
                          />
                          <div className="marketCardBody">
                            <p className="marketgametitle">{e.marketTitle}</p>
                            <div className="mark-icon-wraper">
                              {/* <img src={gameIcon} alt="game icon" /> */}
                              <span>{e.marketGame}</span>
                            </div>
                            <div className="pricewraper">
                              <p>
                                Price <span> Rs {e.marketPrice}</span>
                              </p>
                              <div className="pergift">per Gift</div>
                            </div>
                            <div className="marketbtn">
                              <button
                                className="d-btn"
                                onClick={() => {
                                  props.handleShow();
                                }}
                              >
                                {" "}
                                Buy Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div
                    className="swiper-button-next"
                    style={{ color: "#fff" }}
                  ></div>
                  <div
                    className="swiper-button-prev"
                    style={{ color: "#fff" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* -------------------------------------------- Download Games Section -------------------------------------------- */}
          {props.gameData.length === 0 ? (
            <></>
          ) : (
            <div className="downloadGamesWraper main-padding">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5 dg-ls">
                    <p className="mainHead">Download Games</p>
                    <p className="shortText">
                      Dive into a world of limitless excitement as you embark on
                      a journey to discover the thrilling and most beloved
                      games. Download your favorite games and embark on a
                      journey of endless fun and limitless possibilities.
                      Immerse yourself in captivating stories, engaging
                      gameplay, and stunning visuals that will keep you coming
                      back for more.
                    </p>
                    {/* <button className="d-btn">See All</button> */}

                    <Link
                      className="d-btn"
                      aria-current="page"
                      to="/allgames"
                      style={{ display: "inline-block" }}
                    >
                      See All
                    </Link>
                  </div>
                  <div className="col-md-7 col-sm-12 col-xs-12">
                    <div className="download__game__wraper">
                      <Slider {...settingsInsGames} className="">
                        {props.gameData.map((data, index) => {
                          taglist = data.tag;
                          tagArr = taglist?.split(",");
                          return (
                            <div className="">
                              <Card className=" hp-card  mx-2">
                                <Card.Img
                                  onClick={() => props.navigateGameDetail(data)}
                                  key={index}
                                  variant="top"
                                  src={`${ImgUrl}${data.image}`}
                                  className="game-card-Img"
                                />
                                <Card.Body className="tag-body">
                                  <Card.Title>{data.title}</Card.Title>
                                  {/* {tagArr?.map((tag) => {
                                    return (
                                      <button
                                        onClick={() =>
                                          props.onHandelTagSearch(tag)
                                        }
                                        className="gc-tagbtn"
                                      >
                                        <img src={gametypeicon} alt="tag btn" />
                                        {tag}
                                     
                                      </button>
                                    );
                                  })} */}
                                  {
                                    <button
                                      onClick={() =>
                                        props.onHandelTagSearch(tagArr[0])
                                      }
                                      className="gc-tagbtn"
                                    >
                                      <img src={gametypeicon} alt="tag btn" />
                                      {tagArr[0]}
                                    </button>
                                  }
                                </Card.Body>
                              </Card>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -------------------------------------------- Games Competition Section -------------------------------------------- */}

          {/* <hr className="horLine container my-4 d-" /> */}

          <div className="gameCompetetion main-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="gcom-wraper">
                    <p className="mainHead">GAMES COMPETITIONS</p>
                    <p className="shortText">
                      Compete To Be Top In Leader Board And Win Cash Prizes.
                    </p>
                    <button
                      type="button"
                      className="d-btn"
                      onClick={() => {
                        props.handleShow();
                      }}
                    >
                      SIGN UP{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* -------------------------------------------- Footer Section -------------------------------------------- */}

          {/* footer */}
          <Footer />
          {/* footer */}
        </div>
      </div>
      {/* )} */}
      {props.showModal ? (
        <CommingSoonModal
          showModal={props.showModal}
          handleClose={props.handleClose}
        />
      ) : null}
    </>
  );
};

export default Home;
