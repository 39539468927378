import React, { useContext, useEffect, useState } from "react";
import BrowseVideo from "../Components/BrowseVideo";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import UserContext from "../Utils/User_Context";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";

let taglist = [];
let tagArr = [];
let gameVideoList = [];
const BrowseVideoScreen = () => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const userContext = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    if (state?.BrowseGameData && gameData === null) {
      setGameData(state.BrowseGameData);
      taglist = state.BrowseGameData.game.tag;
      tagArr = taglist.split(",");
      gameVideoList = state.BrowseGameData.gameVideos;
    } else {
      let gameId = searchParams.get("gameId");
      if (gameId) {
        if (userContext.token) onHandelGameDetail(gameId);
      } else {
        navigate("/");
      }
    }
  }, [userContext.token]);

  const onHandelGameDetail = async (gameId) => {
    let ApiName = "";
    if (userContext.msisdn) {
      ApiName = ApiNames.gameDetails + gameId + "/" + userContext.msisdn;
    } else {
      ApiName = ApiNames.gameDetails + gameId;
    }
    try {
      let response = await fetchGet(ApiName, userContext.token);
      if (response.status === "0") {
        setGameData(response.gameDetail);
        taglist = response.gameDetail.game.tag;
        tagArr = taglist.split(",");
        gameVideoList = response.gameDetail.gameVideos;
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const onhandleVideoStream = (index) => {
    navigate("/video?videoId=" + gameVideoList[index].id, {
      state: {
        data: gameVideoList[index],
        allData: gameVideoList,
        type: "mp4",
      },
    });
  };

  const onHandelFollow = async (id) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: id,
      };
      try {
        setIsLoader(true);
        let response = await fetchPost(
          ApiNames.gamesFollow,
          userContext.token,
          data
        );
        if (response.status === 0) {
          setIsLoader(false);
          let _gameDetail = { ...gameData };
          _gameDetail.subscribed = true;
          _gameDetail.followCount = _gameDetail.followCount + 1;
          setGameData(_gameDetail);
        } else {
          setIsLoader(false);
          setErrorMsg(response.result);
        }
      } catch (err) {
        setIsLoader(false);
        setErrorMsg("Something went wrong, Please try again.");
      }
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };

  const onHandelUnFollow = async (id) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: id,
      };
      try {
        setIsLoader(true);
        let response = await fetchPost(
          ApiNames.gamesUnfollow,
          userContext.token,
          data
        );
        if (response.status === 0) {
          setIsLoader(false);
          let _gameDetail = { ...gameData };
          _gameDetail.subscribed = false;
          _gameDetail.followCount = _gameDetail.followCount - 1;
          setGameData(_gameDetail);
        } else {
          setIsLoader(false);
          setErrorMsg(response.result);
        }
      } catch (err) {
        setIsLoader(false);
        setErrorMsg("Something went wrong, Please try again.");
      }
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };

  return (
    <BrowseVideo
      onHandelFollow={onHandelFollow}
      onHandelUnFollow={onHandelUnFollow}
      gameVideoList={gameVideoList}
      tagsList={tagArr}
      gameDetailList={gameData?.game}
      followCount={gameData?.followCount}
      ViewCount={gameData?.viewCount}
      onhandleVideoStream={onhandleVideoStream}
      subscribed={gameData?.subscribed}
      isLoader={isLoader}
      errorMsg={errorMsg}
    />
  );
};

export default BrowseVideoScreen;
