import React, { useEffect, useState } from "react";
import LandingScreen from "./landingScreen";
import SingleLandingScreen from "./SingleLandingScreen";
import { useLocation } from "react-router-dom";

const LandingMainScreen = () => {
  const search = useLocation().search;
  const utm_source = new URLSearchParams(search).get("utm_source");
  const [isOnePager, setIsOnePager] = useState(true);

  useEffect(() => {
    if (utm_source) {
      if (
        utm_source.toLocaleLowerCase() === "cvm" ||
        utm_source.toLocaleLowerCase() === "sms" ||
        utm_source.toLocaleLowerCase() === "jw"
      ) {
        setIsOnePager(true);
      } else {
        setIsOnePager(false);
      }
    } else {
      setIsOnePager(false);
    }
  }, []);

  return <>{isOnePager ? <SingleLandingScreen /> : <LandingScreen />}</>;
};

export default LandingMainScreen;
