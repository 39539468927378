import React from "react";
import "../../style/PrivacyStyle.css";
const Privacypolicy = () => {
  return (
    <div class="jazz_discount_outer" style={{backgroundColor: "#fff"}}>
      <div class="container">
        <div class="discount_inner">
          <h2>Privacy Policy</h2>

          <h3>Introduction:</h3>
          <p>
          PMCL/Jazz/We/Us/Our, part of Pakistan Mobile Communications Limited, presents the Jazz platform, also recognized as "Jazz Game Club." This platform is responsible for handling the data we collect, ensuring compliance with relevant laws and regulations set forth by the Pakistan Telecommunication Authority – PTA or any other regulatory body as per Pakistan's laws. Jazz remains steadfast in delivering quality services, and this privacy framework ("Privacy Guidelines") outlines our continuous commitment to effectively managing your Personal Information.<br></br>
          </p>

          <h3>Automatically Collected Information:</h3>
          <br></br>
          <br></br>
          
          <b>Personal Information Definition:</b><br></br>
          <p>
          Personal Information includes any information that directly and/or indirectly and/or in combination with other information allows identification of an individual or group of individuals. This encompasses details like IP addresses, device information, or online identifiers, even without direct links to names or contact details.
          </p>
          <br></br>

          <b>Agreement to Terms:</b>
          <p>
          By using Game Club, you agree to the collection and use of information outlined in this policy. We commit to not using or sharing your information except as described herein.
          </p>
            <br></br>
            <br></br>
          <h3>Information Collection and Use:</h3>

          <b>1.	Sign-up Information:</b>
          <p>
          When you sign up for Game Club, we collect essential details like your name, phone number and profile picture to authenticate your identity.
          </p><br></br>
          <b>2.	Log Data:</b>
          <p>We collect Log Data in case of app errors, including device IP address, name, operating system version, Game Club configuration, and usage statistics.</p>
            <br></br>
            <b>3.	Cookies:</b>
          <p>While Game Club doesn’t use explicit cookies, third-party code and libraries employed by the app may use them to enhance services. You can choose to accept or refuse these cookies.</p>
          <br></br>
          <b>4.	Device and Technical Usage Information:</b>
          <p>
          We gather information about your device, such as unique identification code, mobile carrier, photos, videos, music, documents, contacts, SMS’s, call log, app list, xclouds, Facebook, Gmail, Dropbox, Instagram, time zone settings, and more. This data aids in improving the app, troubleshooting, and ensuring security.
          </p><br></br>
          <b>5.	Location Information:</b>
          <p>
          Game Club collects location information derived from your IP address, GPS when the app is in use, and other sources. This is used to provide location-based tools, customized services, and value-added services, including marketing.
          </p><br></br>
          <b>6.	Other Ad-Hoc Information:</b>
          <p>
          We may seek feedback through surveys to enhance user experience and may use this information for marketing through Game Club Marketplace and Marketing.
          </p><br></br>
          <b>7.	Aggregated and Anonymized Data:</b>
          <p>
          The collected information is used to create aggregated and anonymized data for internal business planning and to improve Game Club.
          </p><br></br>

          <h3>Security of Personal Information:</h3>
          <b>Storage and Protection:</b><br></br>
          <p>
          Your Personal Information is stored securely to prevent misuse, loss, unauthorized access, modification, or disclosure.
          </p><br></br>
          <b>Legal and Regulatory Compliance:</b><br></br>
          <p>
          We collect, use, and store your Personal Information in adherence to legal and regulatory requirements.
          </p><br></br>
          <b>Data Accuracy:</b><br></br>
          <p>
          We strive to keep your Personal Information accurate, complete, and up-to-date. If you find inaccuracies, please inform us for prompt updates.
          </p><br></br><br></br>

          <b>Service Providers:</b><br></br>
          <p>We may engage third-party companies to facilitate our services, but they are bound not to disclose or use your Personal Information for other purposes.</p><br></br>

          <b>Security:</b><br></br>
          <p>While we use commercially acceptable means to protect your Personal Information, no method is 100% secure. We cannot guarantee absolute security.</p><br></br>

          <b>Links to Other Sites:</b><br></br>
          <p>Game Club may contain links to external sites. We advise reviewing their privacy policies, as we have no control over their practices.</p><br></br>

         <b>Changes to This Privacy Policy:</b><br></br>
          <p>Our Privacy Policy may be updated periodically, with changes effective immediately upon posting. Regularly check for updates.</p><br></br>

            
         <b>Contact Us:</b><br></br>
          <p>For questions or concerns about our Privacy Policy, contact us at <a href="mailto:dpo@jazz.com.pk">dpo@jazz.com.pk</a> </p><br></br>
          <p>Thank you for choosing Game Club. Your privacy and gaming enjoyment are our top priorities.</p><br></br>

          <h3>FAQs:</h3><br></br>
          <b>What about Cookies?</b><br></br>
          <p>Game Club doesn't use cookies explicitly. Third-party code may use cookies; you can accept or refuse them.</p><br></br>
          <b>Does Game Club share received information?</b><br></br>
          <p>No, we don't sell user information. It's shared as described in our Privacy Policy with subsidiaries or service providers for specific functions.</p><br></br>
          <b>How secure is my information?</b><br></br>
          <p>	We use SSL software for secure transmission. Protect your password and sign off on shared devices.</p><br></br>

          <b>Third-party advertisers and links?</b><br></br>
          <p>	Game Club doesn't have them currently; any changes will be updated.</p><br></br>

          <b>Which information can I access?</b><br></br>
          <p>View/update a range of account information and interactions on Game Club.</p><br></br>


          
          <b>Are children allowed to use Game Club?</b><br></br>
          <p>Game Club is for adults; if under 18, use with a parent or guardian's involvement.</p><br></br>
          

          <b>Conditions of use, notices, and revisions</b><br></br>
          <p>Your use is subject to our Conditions of Use. Check our website/app for updates.</p><br></br>

          <b>Examples of information collected</b><br></br>
          <p>Includes sign-up details, log data, device info, location, and survey feedback, used to enhance gaming experience, troubleshoot, and improve Game Club.</p><br></br>
          

          
          <b>Deletion of Data:</b><br></br>
          <p>If you wish to delete data collected during your Game Club usage, unsubscribe or log out via the app/web. All data is flushed, and upon reusing the service, you will be treated as a new user. </p><br></br>
          
          <p><b>Note:</b> Data is used for personalization, tracking, and reporting, encrypted, and not shared with third parties.</p>

         
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
