import React, { useContext, useEffect } from "react";
import UserContext from "../Utils/User_Context";

function ChatLive() {
  const userContext = useContext(UserContext);

  useEffect(() => {
    // if (userContext.token) {
      window.chatwootSettings = {
        hideMessageBubble: false,
        position: "right",
        locale: "en",
        type: "standard",
      };

      (function(d,t) {
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: "3sxnYHpCS4qYEWNgg9ZnGH4o",
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
    // }
  }, []);

  return null;
}

export default ChatLive;
