import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import leaderboardbanner from "../Assets/Images/leader-board-banner.webp";
import leaderboardbannerMobile from "../Assets/Images/leader-board-banner-mobile.webp";
import trophy from "../Assets/Images/trophy.webp";

const LeaderBoard = () => {
  return (
    <div>
      <Navbar />
      <div className="leaderBoard">
        <div className="leader-board-banner">
          <img src={leaderboardbanner} className="dis-desk" alt="leader board banner" />
          <img src={leaderboardbannerMobile} className="dis-mobile" alt="leader board banner" />
          <div className="container">
            <div className="leaderBoardText">
              <h1 className="mainHead">LeaderBoard </h1>
              <p className="shortText">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
                deleniti assumenda sequi dignissimos magnam sed sunt repellat?
                Hic
              </p>
            </div>
          </div>
        </div>
        <div className="leaderboard-table">
          <div className="container">
            <div className="lbwrap">
              <p className="leadertext">Lucky Winner ( August 2023 )</p>
              <div className="hr-line"></div>
              <div className="luckyWinner">
                <div className="lw-left flex">
                  <span className="winner">1</span>
                  <p className="Winner-name">Amir Qayyum </p>
                  <img src={trophy} alt="tropht" className="trophy" />
                </div>
                <p className="price">RS 10,000</p>
              </div>
              <div className="luckyWinner">
                <div className="lw-left flex">
                  <span>2</span>
                  <p className="Winner-name">Amir Qayyum </p>
                </div>
                <p className="price">RS 10,000</p>
              </div>
              <div className="luckyWinner">
                <div className="lw-left flex">
                  <span>3</span>
                  <p className="Winner-name">Amir Qayyum </p>
                </div>
                <p className="price">RS 10,000</p>
              </div>
            </div>
          </div>
        <Footer />
        </div>

      </div>
    </div>
  );
};

export default LeaderBoard;
