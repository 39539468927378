import React, { useContext, useEffect, useState } from "react";
import UserSetting from "../Components/UserSetting";
import UserContext from "../Utils/User_Context";
import { fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import { useNavigate } from "react-router";

const UserSettingScreen = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState(userContext.userDetail?.picture);
  const [imgLoader, setImgLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorImgMsg, setErrorImgMsg] = useState("");

  useEffect(() => {
    setImgUrl(userContext.userDetail?.picture);
  }, [userContext.userDetail]);

  const onHandelChange = async (fName, lName, nick, bio) => {
    try {
      setIsLoader(true);
      let data = {
        userId: userContext.userDetail.userId,
        firstName: fName,
        lastName: lName,
        nick: nick,
        bioText: bio,
      };
      let response = await fetchPost(
        ApiNames.saveProfile,
        userContext.token,
        data
      );
      if (response.status === 0) {
        let _userDetail = { ...userContext.userDetail };
        _userDetail.firstName = fName;
        _userDetail.lastName = lName;
        _userDetail.nick = nick;
        _userDetail.bioText = bio;
        userContext.updateUserDetails(_userDetail);
        setIsLoader(false);
        setErrorMsg("Profile Saved successfully");
        setTimeout(() => {
          navigate("/")
        }, 5000);
      } else {
        setIsLoader(false);

        setErrorMsg(response.result);
      }
    } catch (err) {
      setIsLoader(false);

      setErrorMsg("Something Went Wrong..!");
    }
  };
  const onuploadImagepressed = (e) => {
    setImgLoader(true);
    const MAX_FILE_SIZE = 3072;
    const file = e.target.files[0];
    const fileSizeKiloBytes = file.size / 1024;

    if (fileSizeKiloBytes < MAX_FILE_SIZE) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let imgUri = reader.result?.toString();
        let base64Img = imgUri?.split(",")[1];
        uploadImage(base64Img, imgUri);
      };
    } else {
      setImgLoader(false);

      setErrorImgMsg("Image size Should be less than 10MB..!");
    }
  };

  const uploadImage = async (base64Img, imgUri) => {
    try {
      let data = {
        userId: userContext.userDetail.userId,
        picture: base64Img,
      };
      let response = await fetchPost(
        ApiNames.uploadPicture,
        userContext.token,
        data
      );
      if (response.status === 0) {
        setImgLoader(false);
        setImgUrl(imgUri);
        let _userDetail = { ...userContext.userDetail };
        _userDetail.picture = imgUri;
        userContext.updateUserDetails(_userDetail);
        setErrorImgMsg("Image Upload successfully");
      } else {
        setImgLoader(false);
        setErrorImgMsg("Image Upload successfully");
      }
    } catch (err) {
      setImgLoader(false);
      setErrorImgMsg("Something Went Wrong..!");
    }
  };

  return (
    <div>
      <UserSetting
        userDetail={userContext.userDetail}
        onHandelChange={onHandelChange}
        onuploadImagepressed={onuploadImagepressed}
        imgUrl={imgUrl}
        imgLoader={imgLoader}
        isLoader={isLoader}
        errorMsg={errorMsg}
        errorImgMsg={errorImgMsg}
      />
    </div>
  );
};

export default UserSettingScreen;
