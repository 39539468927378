import React, { useState, useEffect, useContext } from "react";
import LiveStream from "../Components/LiveStream";

import { fetchGet, fetchPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import { useNavigate } from "react-router-dom";
import UserContext from "../Utils/User_Context";

function LiveStreamScreen(props) {
  const [streamData, setStreamData] = useState([]);
  const [isLoader, setIsloader] = useState(true);
  const userContext = useContext(UserContext);
  const [tabIndex, setTabIndex] = useState(1);
  const [streamersData, setStreamersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [SteamersFilteredData, setSteamersFilteredData] = useState([]);
  const [gameFilteredData, setGameFilteredData] = useState([]);
  const [reLoader, setReloader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [subIndex, setSubIndex] = useState(null);
  const [streamingGamesData, setstreamingGamesData] = useState([]);

  const [videoIndex, setVideoIndex] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.token) {
      getVideoList(`/${userContext.msisdn}/50`);
      StreamerGamesList();
    }
  }, [userContext.token]);

  useEffect(() => {
    setErrMsg(" ");
    if (userContext.token) {
      if (streamersData.length !== 0) {
        if (userContext.msisdn) {
          handelStreamersList(
            `${ApiNames.streamerPopular}${userContext.msisdn}`
          );
        } else {
          handelStreamersList(ApiNames.streamerPopular);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (reLoader === true) {
      if (inputValue) {
        handleListLoaderforFilter();
        setReloader(false);
      } else {
        handleListreLoader();
        setReloader(false);
      }
    }
  }, [reLoader]);

  const onHandleNavigate = (index) => {
    navigate("/video?videoId=" + streamData[index].id, {
      state: {
        data: streamData[index],
        allData: streamData,
        type: "mp4",
      },
    });
  };

  const getVideoList = async (e) => {
    setIsloader(true);
    try {
      let response = await fetchGet(ApiNames.video + e, userContext.token);
      if (response.status === "0") {
        let res = response.streams;
        if (res.length === 0) {
          setStreamData(null);
        } else {
          setStreamData(response.streams);
        }
        setIsloader(false);
      } else {
        setStreamData(null);
        setIsloader(false);
      }
    } catch (err) {
      setStreamData(null);
      setIsloader(false);
    }
  };

  // Streamer Games

  const StreamerGamesList = async () => {
    setIsloader(true);
    try {
      let response = await fetchGet(
        ApiNames.gamesList + "/100/4",
        userContext.token
      );
      if (response.status === "0") {
        let res = response.games;
        if (res.length === 0) {
          setstreamingGamesData(null);
        } else {
          setstreamingGamesData(response.games);
        }
        setIsloader(false);
      } else {
        setstreamingGamesData(null);
        setIsloader(false);
      }
    } catch (err) {
      setstreamingGamesData(null);
      setIsloader(false);
    }
  };
  // Streamers Section

  const handelStreamersList = async (ApiName) => {
    setIsloader(true);
    try {
      let response = await fetchGet(ApiName, userContext.token);
      if (response.status === "0") {
        let res = response.streamers;
        if (res.length === 0) {
          setStreamersData(null);
        } else {
          setStreamersData(response.streamers);
          if (inputValue) {
            const filtered = response.streamers.filter(
              (item) =>
                item.firstName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                item.lastName.toLowerCase().includes(inputValue.toLowerCase())
            );
            setSteamersFilteredData(filtered);
          }
        }
        setIsloader(false);
      } else {
        setStreamersData(null);
        setIsloader(false);
      }
    } catch (err) {
      setStreamersData(null);
      setIsloader(false);
    }
  };

  const onhandelUnsub = async (element, index) => {
    setSubIndex(index);
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: element,
      };
      try {
        let response = await fetchPost(
          ApiNames.streamerUnsubscribe,
          userContext.token,
          data
        );
        if (response.status === 0) {
          setReloader(!reLoader);
          setVideoIndex(index);

          setErrMsg(response.result);
        } else {
          setErrMsg(response.result);
        }
      } catch (err) {
        setErrMsg("Something Went Wrong..!");
      }
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };
  const onhandelSub = async (element, index) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: element,
      };
      try {
        setSubIndex(index);
        let response = await fetchPost(
          ApiNames.streamerSubscribe,
          userContext.token,
          data
        );
        if (response.status === 0) {
          setReloader(!reLoader);
          setVideoIndex(index);

          setErrMsg(response.result);
        } else {
          setErrMsg(response.result);
        }
      } catch (err) {
        setErrMsg("Something Went Wrong..!");
      }
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };
  // Streamers Section

  const searchHandler = (query) => {
    setInputValue(query);

    if (tabIndex === 3 && streamersData) {
      const filtered = streamersData.filter(
        (item) =>
          item.firstName.toLowerCase().includes(query.toLowerCase()) ||
          item.lastName.toLowerCase().includes(query.toLowerCase())
      );
      setSteamersFilteredData(filtered);
    } else if (tabIndex === 1 && streamData) {
      const filtered = streamData.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    } else if (tabIndex === 2 && streamingGamesData) {
      const filtered = streamingGamesData.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
      setGameFilteredData(filtered);
    }
  };

  const TabSelector = (index) => {
    setSteamersFilteredData([]);
    setFilteredData([]);
    setInputValue("");
    setTabIndex(index);

    if (index === 3 && streamersData.length === 0) {
      if (userContext.msisdn) {
        handelStreamersList(ApiNames.streamerPopular + userContext.msisdn);
      } else {
        handelStreamersList(ApiNames.streamerPopular);
      }
    }
  };
  const navigateGameDetail = async (gameId) => {
    let Api_Name = "";
    if (userContext.msisdn) {
      Api_Name = ApiNames.gameDetails + gameId.id + "/" + userContext.msisdn;
    } else {
      Api_Name = ApiNames.gameDetails + gameId.id;
    }
    try {
      let response = await fetchGet(Api_Name, userContext.token);
      if (response.status === "0") {
        navigate("/browseVideo?gameId=" + response.gameDetail.game.id, {
          state: {
            BrowseGameData: response.gameDetail,
          },
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };
  const PlayNow = (webGameUrl) => {
    if (userContext.msisdn) {
      navigate("/playGames", {
        state: {
          webGameUrl: webGameUrl,
        },
      });
    } else {
      window.location.replace(window.location.origin + "/onboarding.html");
    }
  };
  const Download = async (index) => {
    if (userContext.msisdn) {
      let data = {
        msisdn: userContext.msisdn,
        resourceId: index,
      };
      try {
        let response = await fetchPost(
          ApiNames.gamesDownloads,
          userContext.token,
          data
        );
        if (response.status === 0) {
          console.log("success");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onHandelTagSearch = (tag) => {
    navigate("/search", {
      state: {
        tag: tag,
      },
    });
  };

  const handleListreLoader = () => {
    if (streamersData) {
      if (streamersData[videoIndex].isChannelSubscribed === true) {
        streamersData[videoIndex].isChannelSubscribed = false;
        console.table("fliter" + streamersData[videoIndex].subscriberCount);
        streamersData[videoIndex].subscriberCount =
          streamersData[videoIndex].subscriberCount - 1;
      } else {
        streamersData[videoIndex].isChannelSubscribed = true;

        console.table("fliter" + streamersData[videoIndex].subscriberCount);
        streamersData[videoIndex].subscriberCount =
          streamersData[videoIndex].subscriberCount + 1;
      }

      setStreamData(streamersData);
    }
  };
  const handleListLoaderforFilter = () => {
    if (SteamersFilteredData.length > 0) {
      if (SteamersFilteredData[videoIndex].isChannelSubscribed === true) {
        SteamersFilteredData[videoIndex].isChannelSubscribed = false;
        console.log("fliter" + SteamersFilteredData[videoIndex]);
        SteamersFilteredData[videoIndex].subscriberCount =
          SteamersFilteredData[videoIndex].subscriberCount - 1;
      } else {
        SteamersFilteredData[videoIndex].isChannelSubscribed = true;
        console.log("fliter" + SteamersFilteredData[videoIndex]);
        SteamersFilteredData[videoIndex].subscriberCount =
          SteamersFilteredData[videoIndex].subscriberCount + 1;
      }
      setSteamersFilteredData(SteamersFilteredData);
    }
  };

  return (
    <>
      <LiveStream
        searchHandler={searchHandler}
        filteredData={filteredData}
        inputValue={inputValue}
        TabSelector={TabSelector}
        tabIndex={tabIndex}
        streamData={streamData}
        onHandleNavigate={onHandleNavigate}
        isLoader={isLoader}
        SteamersFilteredData={SteamersFilteredData}
        onhandelUnsub={onhandelUnsub}
        onhandelSub={onhandelSub}
        streamersData={streamersData}
        errMsg={errMsg}
        subIndex={subIndex}
        streamingGamesData={streamingGamesData}
        gameFilteredData={gameFilteredData}
        navigateGameDetail={navigateGameDetail}
        PlayNow={PlayNow}
        Download={Download}
        onHandelTagSearch={onHandelTagSearch}
      />
    </>
  );
}

export default LiveStreamScreen;
