import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ImgUrl } from "../Utils/FetchApi";
import { useEffect } from "react";

const PlayGame = () => {
    const { state } = useLocation();
  return (
    <iframe
      src={ImgUrl+state?.webGameUrl}
      allowfullscreen="allowfullscreen"
      style={{ width: "100%", height: "100vh" }}
    ></iframe>
  );
};

export default PlayGame;
