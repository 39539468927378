import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
// import Streamers from "./Common/Streamers";
import { Container, Row, Col, Card } from "react-bootstrap";
import Streamers from "./Common/Streamers";
import LoaderSpiner from "../Components/LoaderSpiner";
import UserContext from "../Utils/User_Context";
import { useNavigate } from "react-router-dom";
import { ImgUrl } from "../Utils/FetchApi";
import gametypeicon from "../Assets/Images/game-type-icon.webp";

let taglist = [];
let tagArr = [];

const GlobalSearch = (props) => {
  return (
    <div>
      <Navbar />
      <div className="mainBgSection">
        <div className="globalsearchwraper">
          <div className="container">
            <div className="searchBox">
              <div className="g-s-btn">
                <div className="searchIcon">
                  <input
                    className="gs-input"
                    type="text"
                    value={props.inputValue}
                    placeholder="Search"
                    id="searchInput"
                    onChange={(e) => props.searchHandler(e.target.value)}
                    maxLength={10}
                    onKeyDown={props.searchEventHandlerEnter}
                  />
                  <button
                    id="searchButton"
                    onClick={() => props.searchEventHandler()}
                  >
                    <svg
                      stroke="white"
                      strokeWidth="0.3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search header__button-icon iconMobile"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                    </svg>
                  </button>
                </div>
              </div>

              {props.loader === false ? (
                <>
                  {props.inputValue === "" || props.searchResult === null ? (
                    <div className="noDataStyle">Please Enter Game Name to Search..</div>
                  ) : (
                    <>
                      {props.tabIndex === 1 ? (
                        <>
                          {props.searchResult?.streamers.length === 0 &&
                          props.searchResult?.games.length === 0 &&
                          props.concatArrLimited.length === 0 ? (
                            <div className="noDataStyle">No Record Found</div>
                          ) : (
                            <>
                              {/* Games   */}
                              {props.searchResult?.games.length > 0 ? (
                                <div className="g-s-games">
                                  <div className="container">
                                    <div className="gc-gs-seeall">
                                      <p>GAMES</p>
                                      <div
                                        className="gc-see-all"
                                        onClick={() => {
                                          props.setTabIndex(3);
                                        }}
                                      >
                                        <p>See all</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="">
                                    <Container className="my-3">
                                      <Row
                                        xs={1}
                                        sm={2}
                                        md={2}
                                        lg={4}
                                        className="g-4 "
                                      >
                                        {props.gameArrLimited.map(
                                          (data, index) => {
                                            taglist = data.tag;
                                            tagArr = taglist?.split(",");
                                            return (
                                              <Col key={index}>
                                                <Card className="hp-card">
                                                  <div
                                                    style={{
                                                      overflow: "hidden",
                                                    }}
                                                  >
                                                    <Card.Img
                                                      onClick={() =>
                                                        props.navigateGameDetail(
                                                          data
                                                        )
                                                      }
                                                      variant="top"
                                                      src={`${ImgUrl}${data.image}`}
                                                      className="gameCardImg"
                                                    />
                                                  </div>

                                                  <Card.Body className="tag-body">
                                                    <div className="flex mb-3">
                                                      <Card.Title className="gc-title">
                                                        {data.title}
                                                      </Card.Title>
                                                    </div>

                                                    <div className="shootActionBtn">
                                                      {/* {tagArr?.map((tag) => {
                                                        return (
                                                          <button
                                                            onClick={() =>
                                                              props.onHandelTagSearch(
                                                                tag
                                                              )
                                                            }
                                                            className="gc-tagbtn"
                                                          >
                                                            <img
                                                              src={gametypeicon}
                                                              alt="tag btn"
                                                            />
                                                            {tag}
                                                          </button>
                                                        );
                                                      })} */}
                                                      {
                                                        <button
                                                          onClick={() =>
                                                            props.onHandelTagSearch(
                                                              tagArr[0]
                                                            )
                                                          }
                                                          className="gc-tagbtn"
                                                        >
                                                          <img
                                                            src={gametypeicon}
                                                            alt="tag btn"
                                                          />
                                                          {tagArr[0]}
                                                        </button>
                                                      }
                                                    </div>
                                                    {/* <button className='cardBtnLvlUp py-1 d-none d-sm-block d-inline'>Level UP</button> */}
                                                  </Card.Body>
                                                </Card>
                                              </Col>
                                            );
                                          }
                                        )}
                                      </Row>
                                    </Container>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      ) : props.tabIndex === 3 ? (
                        <>
                          {/* Games   */}
                          {props.searchResult?.games.length > 0 ? (
                            <div className="g-s-games">
                              <div className="container">
                                <div className="gc-gs-seeall">
                                  <p>GAMES</p>
                                </div>
                              </div>
                              <div className="mt-4">
                                <Container className="">
                                  <Row
                                    xs={1}
                                    sm={2}
                                    md={2}
                                    lg={4}
                                    className="g-4 "
                                  >
                                    {props.searchResult?.games.map(
                                      (data, index) => {
                                        taglist = data.tag;
                                        tagArr = taglist?.split(",");
                                        return (
                                          <Col key={index}>
                                            <Card className="hp-card">
                                              <div
                                                style={{ overflow: "hidden" }}
                                              >
                                                <Card.Img
                                                  onClick={() =>
                                                    props.navigateGameDetail(
                                                      data
                                                    )
                                                  }
                                                  variant="top"
                                                  src={`${ImgUrl}${data.image}`}
                                                  className="gameCardImg"
                                                />
                                              </div>

                                              <Card.Body className="tag-body">
                                                <div className="flex mb-3">
                                                  <Card.Title className="gc-title">
                                                    {data.title}
                                                  </Card.Title>
                                                </div>
                                                <div className="shootActionBtn">
                                                  {/* {tagArr?.map((tag) => {
                                                    return (
                                                      <button
                                                        onClick={() =>
                                                          props.onHandelTagSearch(
                                                            tag
                                                          )
                                                        }
                                                        className="gc-tagbtn"
                                                      >
                                                        <img
                                                          src={gametypeicon}
                                                          alt="tag btn"
                                                        />
                                                        {tag}
                                                      </button>
                                                    );
                                                  })} */}
                                                  {
                                                    <button
                                                      onClick={() =>
                                                        props.onHandelTagSearch(
                                                          tagArr[0]
                                                        )
                                                      }
                                                      className="gc-tagbtn"
                                                    >
                                                      <img
                                                        src={gametypeicon}
                                                        alt="tag btn"
                                                      />
                                                      {tagArr[0]}
                                                    </button>
                                                  }
                                                </div>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          ) : (
                            <div className="noDataStyle">No Record Found</div>
                          )}
                        </>
                      ) : (
                        <div className="noDataStyle">No Record Found</div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="noDataStyle">
                  <LoaderSpiner />
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default GlobalSearch;
