import React, { useContext, useEffect, useState } from "react";
import Awareness from "../Components/Awareness";
import { useLocation, useNavigate } from "react-router-dom";
import ApiNames from "../Constants/ApiNames";
import { fetchGet, fetchPost } from "../Utils/FetchApi";
import UserContext from "../Utils/User_Context";
import tagManagerEvents from "../Utils/GoogleTagManager";
import { encode as base64_encode } from "base-64";

let adNetwork = "";
const AwarenessScreen = () => {
  const userContext = useContext(UserContext);
  const search = useLocation().search;
  const _url = new URLSearchParams(search).get("msisdn");

  const encodeMsisdn = encodeURIComponent(_url);
  const utm_source = new URLSearchParams(search).get("utm_source");

  useEffect(() => {
    if (userContext.token) {
      checkUtm();
      authFlow();
    }
  }, [userContext.token]);

  const checkUtm = async () => {
    try {
      let response = await fetchGet(ApiNames.utm, userContext.token);
      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (utm_source === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const authFlow = async () => {
    try {
      let response = await fetchGet(
        ApiNames.awarenessFlow +
          "?utm_source=" +
          utm_source +
          "&msisdn=" +
          encodeMsisdn,
        userContext.token
      );
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("signup", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("signup", "econceptions");
        } else {
          tagManagerEvents("signup", utm_source);
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(response.msisdn);
        url = `${url}/?user=${encodedMsisdn}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        navigateToHome();
        tagManagerEvents("signupFailed", utm_source);
      }
    } catch (err) {
      navigateToHome();
    }
  };

  // const headerEnrichment = (response) => {
  //   try {
  //     if (response.isSpecialFlow === true) {
  //       if (response.msisdn && response.otp) {
  //         subscriberUser(
  //           response.isSpecialFlow && response.fastFlow
  //             ? ApiNames.subscribeFastFlow
  //             : ApiNames.subscribeWeb,
  //           response.otp,
  //           response.msisdn,
  //           response.subPackage,
  //           response.otpId
  //         );
  //       } else {
  //         navigateToHome();
  //       }
  //     } else {
  //       navigateToHome();
  //     }
  //   } catch (error) {
  //     navigateToHome();
  //   }
  // };

  // const subscriberUser = async (apiName, otp, msisdn, userPackage, otpId) => {
  //   let data = {
  //     msisdn: msisdn,
  //     nick: "GW-guestUser",
  //     otp: otp,
  //     utm_source: utm_source,
  //     subPackage: userPackage,
  //     otpId: otpId,
  //     gameId: null,
  //   };

  //   try {
  //     let response = await fetchPost(apiName, userContext.token, data);
  //     if (response.status === 0) {
  //       if (
  //         adNetwork.toLowerCase() === "tiktok" ||
  //         adNetwork.toLowerCase() === "tik tok"
  //       ) {
  //         tagManagerEvents("signup", "econTikTok");
  //       } else if (adNetwork.toLowerCase() === "google") {
  //         tagManagerEvents("signup", "econceptions");
  //       } else {
  //         tagManagerEvents("signup", utm_source);
  //       }
  //       let url = window.location.origin;
  //       let encodedMsisdn = base64_encode(msisdn);
  //       url = `${url}/?user=${encodedMsisdn}`;
  //       if (url.includes("http:")) {
  //         url = url.replace("http", "https");
  //       }
  //       window.location.replace(url);
  //     } else {
  //       navigateToHome();
  //       tagManagerEvents("signupFailed", utm_source);
  //     }
  //   } catch (err) {
  //     navigateToHome();
  //     tagManagerEvents("signupFailed", utm_source);
  //   }
  // };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  return <Awareness />;
};

export default AwarenessScreen;
